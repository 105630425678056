import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TableColumnsProp } from '../../models/tables.model';
import { CommonTableService } from '../../services/common-table.service';
import { dateTimeFormat } from '../../utility/constants';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { NgxPrintService } from 'ngx-print';

@Component({
  selector: 'app-summary-page-print',
  templateUrl: './summary-page-print.component.html',
  styleUrl: './summary-page-print.component.scss',
  standalone: true,
  imports: [CommonModule, MaterialModule],
})
export class SummaryPagePrintComponent implements OnInit {
  public dataSource = new MatTableDataSource<any>([]);
  public displayedColumns!: string[];
  public summaryCustomiseTableColumns!: TableColumnsProp[];
  public isFrom!: string;
  public dateFormat = dateTimeFormat.onlyDate;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public commonTableService: CommonTableService,
    private printService: NgxPrintService
  ) {}

  ngOnInit(): void {
    this.commonTableService.printTableData
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (Object.keys(data).length) {
          this.displayedColumns = data.displayColumns;
          this.summaryCustomiseTableColumns = data.customizeColumns;
          this.dataSource.data = data.selectedData;
          setTimeout(() => {
            const customPrintOptions: any = {
              printSectionId: 'print-section',
              useExistingCss: true,
              closeWindow: true,
            };
            this.printService.print(customPrintOptions);
          });
        }
      });
  }
}
