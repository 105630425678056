import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';

// import {MatToolbarModule} from '@angular/material/toolbar';
// import {MatSidenavModule} from '@angular/material/sidenav';
// import {MatListModule} from '@angular/material/list';
// import {MatIconModule} from '@angular/material/icon';

import { MaterialModule } from '../../material.module';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { AppBreadcrumbComponent } from '../app-breadcrumb/app-breadcrumb.component';
import { SummaryPagePrintComponent } from '../summary-page-print/summary-page-print.component';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    SvgIconComponent,
    AppBreadcrumbComponent,
    SummaryPagePrintComponent,
  ],
  exports: [LayoutComponent],
})
export class LayoutModule {}
