export interface MenuItem {
  label: string;
  icon: string;
  routerLink: string;
  iconName: string;
  iconClass: string;
}

export const menuItems: MenuItem[] = [
  {
    label: 'Dashboard',
    icon: '/assets/icons/dashboard.svg',
    routerLink: 'dashboard',
    iconName: 'dashboard',
    iconClass: 'dashboardIcon',
  },
  {
    routerLink: 'hawb',
    label: 'Shipments',
    icon: '/assets/icons/hawb.svg',
    iconName: 'hawb',
    iconClass: 'hawbIcon',
  },
  {
    iconName: 'bags',
    label: 'Bags',
    icon: '/assets/icons/bags.svg',
    routerLink: 'bag',
    iconClass: 'bagsIcon',
  },
  {
    iconClass: 'manifestIcon',
    label: 'Manifests',
    icon: '/assets/icons/manifest.svg',
    routerLink: 'manifest',
    iconName: 'manifest',
  },
  {
    label: 'MAWBs',
    routerLink: 'mawb',
    iconName: 'mawb',
    iconClass: 'mawbIcon',
    icon: '/assets/icons/mawb.svg',
  },
  {
    label: 'Accounts Payable',
    icon: '/assets/icons/receivable.svg',
    routerLink: '',
    iconName: 'accountPay',
    iconClass: 'accountIcon',
  },
  {
    label: 'Accounts Receivable',
    icon: '/assets/icons/payable.svg',
    iconName: 'accountReceive',
    routerLink: '',
    iconClass: 'accountsReceive ',
  },
  {
    label: 'Alerts',
    iconName: 'alert',
    icon: '/assets/icons/alert.svg',
    routerLink: '',
    iconClass: 'alertIcon',
  },
  {
    label: 'Setup & Maintenance',
    routerLink: 'sm-dashboard',
    iconName: 'setting',
    icon: '/assets/icons/setting.svg',
    iconClass: 'settingIcon',
  },
];
