<div class="criteria-container" [formGroup]="manifestCriteriaInfoForm">
  <div class="">
    <mat-form-field class="mat-md" appearance="outline">
      <mat-label>Shipment Final Destination</mat-label>
      <input
        matInput
        formControlName="hawbFinalDestination"
        [matAutocomplete]="hawbDestinationAutoComplete"
        (blur)="checkOriginAndShipmentFinalDestination('hawbFinalDestination')"
        (input)="
          checkOriginAndShipmentFinalDestination('hawbFinalDestination');
          formAutocompleteValueChanges(
            'hawbFinalDestination',
            'hawbFinalDestinationId',
            hawbFinalDestinationData
          )
        " />
      <mat-autocomplete
        #hawbDestinationAutoComplete="matAutocomplete"
        (optionSelected)="
          commonService.getAutocompleteDropdownBinding(
            hawbFinalDestinationData,
            manifestCriteriaInfoForm,
            'hawbFinalDestination',
            'hawbFinalDestinationId',
            'name'
          );
          dispatchFormValues()
        ">
        <mat-option
          [value]="item.name"
          *ngFor="let item of filteredHawbFinalDestinationOptions | async">
          {{ item.name }}
        </mat-option>
      </mat-autocomplete>
      <app-svg-icon
        *ngIf="
          checkControlHasError('hawbFinalDestination') ||
          shipmentDestinationError
        "
        matSuffix
        [iconName]="actionIconsList.infoCircle"
        [isStaticSvg]="true"
        [isFrom]="svgIconFromList.page"
        class="cursor"
        (click)="fetchWidth($event, 'manifest-hawbFinalDestination')"
        (keydown)="$event.stopPropagation()"></app-svg-icon>
      <app-custom-error-tooltip
        *ngIf="
          (checkControlHasError('hawbFinalDestination') ||
            shipmentDestinationError) &&
          setManifestCriteriaErrorTooltip['manifest-hawbFinalDestination']
        "
        [tooltipWidth]="
          setManifestCriteriaWidth['manifest-hawbFinalDestination']
        "
        [tooltipText]="
          !shipmentDestinationError
            ? getErrorMessage('hawbFinalDestination')
            : orgdestError
        "
        (tooltipCloseClicked)="
          commonService.closeErrorTooltip(
            'manifest-hawbFinalDestination',
            setManifestCriteriaErrorTooltip
          )
        ">
      </app-custom-error-tooltip>
    </mat-form-field>

    <mat-form-field class="mat-md" appearance="outline">
      <mat-label>Facility Type</mat-label>
      <input
        matInput
        formControlName="facilityType"
        [matAutocomplete]="facilityTypeAutoComplete"
        (input)="
          formAutocompleteValueChanges(
            'facilityType',
            'facilityTypeId',
            facilityData
          )
        " />
      <mat-autocomplete
        #facilityTypeAutoComplete="matAutocomplete"
        (optionSelected)="
          commonService.getAutocompleteDropdownBinding(
            facilityData,
            manifestCriteriaInfoForm,
            'facilityType',
            'facilityTypeId',
            'name'
          );
          dispatchFormValues()
        ">
        <mat-option
          [value]="item.name"
          *ngFor="let item of filteredFacilityOptions | async">
          {{ item.name }}
        </mat-option>
      </mat-autocomplete>
      <app-svg-icon
        *ngIf="checkControlHasError('facilityType')"
        matSuffix
        [iconName]="actionIconsList.infoCircle"
        [isStaticSvg]="true"
        [isFrom]="svgIconFromList.page"
        class="cursor"
        (click)="fetchWidth($event, 'manifest-facilityType')"
        (keydown)="$event.stopPropagation()"></app-svg-icon>
      <app-custom-error-tooltip
        *ngIf="
          checkControlHasError('facilityType') &&
          setManifestCriteriaErrorTooltip['manifest-facilityType']
        "
        [tooltipWidth]="setManifestCriteriaWidth['manifest-facilityType']"
        [tooltipText]="getErrorMessage('facilityType')"
        (tooltipCloseClicked)="
          commonService.closeErrorTooltip(
            'manifest-facilityType',
            setManifestCriteriaErrorTooltip
          )
        ">
      </app-custom-error-tooltip>
    </mat-form-field>

    <mat-form-field class="mat-md" appearance="outline">
      <mat-label>Airline</mat-label>
      <input
        placeholder="Airline Code and Name"
        matInput
        formControlName="airline"
        [matAutocomplete]="airlineAutoComplete"
        (input)="
          formAutocompleteValueChanges('airline', 'airlineId', airlineCodeData)
        " />
      <mat-autocomplete
        #airlineAutoComplete="matAutocomplete"
        (optionSelected)="
          commonService.getAutocompleteDropdownBinding(
            airlineCodeData,
            manifestCriteriaInfoForm,
            'airline',
            'airlineId',
            'name'
          );
          dispatchFormValues()
        ">
        <mat-option
          [value]="item.name"
          *ngFor="let item of filteredAirlineCodeOptions | async">
          {{ item.name }}
        </mat-option>
      </mat-autocomplete>
      <app-svg-icon
        *ngIf="checkControlHasError('airline')"
        matSuffix
        [iconName]="actionIconsList.infoCircle"
        [isStaticSvg]="true"
        [isFrom]="svgIconFromList.page"
        class="cursor"
        (click)="fetchWidth($event, 'manifest-airline')"
        (keydown)="$event.stopPropagation()"></app-svg-icon>
      <app-custom-error-tooltip
        *ngIf="
          checkControlHasError('airline') &&
          setManifestCriteriaErrorTooltip['manifest-airline']
        "
        [tooltipWidth]="setManifestCriteriaWidth['manifest-airline']"
        [tooltipText]="getErrorMessage('airline')"
        (tooltipCloseClicked)="
          commonService.closeErrorTooltip(
            'manifest-airline',
            setManifestCriteriaErrorTooltip
          )
        ">
      </app-custom-error-tooltip>
    </mat-form-field>

    <mat-form-field class="mat-md" appearance="outline">
      <mat-label>Clearance Type</mat-label>
      <input
        matInput
        formControlName="clearanceType"
        [matAutocomplete]="clearanceTypeAutocomplete"
        (input)="
          commonService.getAutocompleteDropdownIdParam(
            clearanceTypeData,
            manifestCriteriaInfoForm,
            'clearanceType',
            'clearanceTypeCode'
          )
        " />
      <mat-autocomplete
        #clearanceTypeAutocomplete="matAutocomplete"
        (optionSelected)="
          commonService.getAutocompleteDropdownBinding(
            clearanceTypeData,
            manifestCriteriaInfoForm,
            'clearanceType',
            'clearanceTypeCode',
            'id'
          );
          dispatchFormValues()
        ">
        <mat-option
          [value]="item.id"
          *ngFor="let item of filteredClearanceTypeOptions | async">
          {{ item.id }}
        </mat-option>
      </mat-autocomplete>
      <app-svg-icon
        *ngIf="checkControlHasError('clearanceType')"
        matSuffix
        [iconName]="actionIconsList.infoCircle"
        [isStaticSvg]="true"
        [isFrom]="svgIconFromList.page"
        class="cursor"
        (click)="fetchWidth($event, 'manifest-clearanceType')"
        (keydown)="$event.stopPropagation()"></app-svg-icon>
      <app-custom-error-tooltip
        *ngIf="
          checkControlHasError('clearanceType') &&
          setManifestCriteriaErrorTooltip['manifest-clearanceType']
        "
        [tooltipWidth]="setManifestCriteriaWidth['manifest-clearanceType']"
        [tooltipText]="getErrorMessage('clearanceType')"
        (tooltipCloseClicked)="
          commonService.closeErrorTooltip(
            'manifest-clearanceType',
            setManifestCriteriaErrorTooltip
          )
        ">
      </app-custom-error-tooltip>
    </mat-form-field>
  </div>

  <div class="">
    <mat-form-field class="mat-md" appearance="outline">
      <mat-label>Service Type Group</mat-label>
      <input
        matInput
        formControlName="serviceTypeGroup"
        [matAutocomplete]="serviceTypeGroupAutocomplete"
        (input)="
          commonService.getAutocompleteDropdownIdParam(
            serviceTypeData,
            manifestCriteriaInfoForm,
            'serviceTypeGroup',
            'serviceTypeGroupCode'
          )
        " />
      <mat-autocomplete
        #serviceTypeGroupAutocomplete="matAutocomplete"
        (optionSelected)="
          commonService.getAutocompleteDropdownBinding(
            serviceTypeData,
            manifestCriteriaInfoForm,
            'serviceTypeGroup',
            'serviceTypeGroupCode',
            'name'
          );
          dispatchFormValues()
        ">
        <mat-option
          [value]="item.name"
          *ngFor="let item of filteredServiceTypeOptions | async">
          {{ item.name }}
        </mat-option>
      </mat-autocomplete>
      <app-svg-icon
        *ngIf="checkControlHasError('serviceTypeGroup')"
        matSuffix
        [iconName]="actionIconsList.infoCircle"
        [isStaticSvg]="true"
        [isFrom]="svgIconFromList.page"
        class="cursor"
        (click)="fetchWidth($event, 'manifest-serviceTypeGroup')"
        (keydown)="$event.stopPropagation()"></app-svg-icon>
      <app-custom-error-tooltip
        *ngIf="
          checkControlHasError('serviceTypeGroup') &&
          setManifestCriteriaErrorTooltip['manifest-serviceTypeGroup']
        "
        [tooltipWidth]="setManifestCriteriaWidth['manifest-serviceTypeGroup']"
        [tooltipText]="getErrorMessage('serviceTypeGroup')"
        (tooltipCloseClicked)="
          commonService.closeErrorTooltip(
            'manifest-serviceTypeGroup',
            setManifestCriteriaErrorTooltip
          )
        ">
      </app-custom-error-tooltip>
    </mat-form-field>
    <mat-form-field class="mat-md" appearance="outline">
      <mat-label>Object Group</mat-label>
      <input
        matInput
        formControlName="objectGroupId"
        (input)="dispatchFormValues()" />
    </mat-form-field>
    <mat-form-field class="mat-md" appearance="outline">
      <mat-label>Customer</mat-label>
      <input
        placeholder="Customer ID and Name"
        matInput
        formControlName="customer"
        [matAutocomplete]="customerAutocomplete"
        (input)="
          formAutocompleteValueChanges('customer', 'customerId', customerData)
        " />
      <mat-autocomplete
        #customerAutocomplete="matAutocomplete"
        (optionSelected)="
          commonService.getAutocompleteDropdownBinding(
            customerData,
            manifestCriteriaInfoForm,
            'customer',
            'customerId',
            'name'
          );
          dispatchFormValues()
        ">
        <mat-option
          [value]="item.name"
          *ngFor="let item of filteredCustomerOptions | async">
          {{ item.name }}
        </mat-option>
      </mat-autocomplete>
      <app-svg-icon
        *ngIf="checkControlHasError('customer')"
        matSuffix
        [iconName]="actionIconsList.infoCircle"
        [isStaticSvg]="true"
        [isFrom]="svgIconFromList.page"
        class="cursor"
        (click)="fetchWidth($event, 'manifest-customer')"
        (keydown)="$event.stopPropagation()"></app-svg-icon>
      <app-custom-error-tooltip
        *ngIf="
          checkControlHasError('customer') &&
          setManifestCriteriaErrorTooltip['manifest-customer']
        "
        [tooltipWidth]="setManifestCriteriaWidth['manifest-customer']"
        [tooltipText]="getErrorMessage('customer')"
        (tooltipCloseClicked)="
          commonService.closeErrorTooltip(
            'manifest-customer',
            setManifestCriteriaErrorTooltip
          )
        ">
      </app-custom-error-tooltip>
    </mat-form-field>
  </div>
  <div>
    <div>
      <mat-form-field appearance="outline" class="mat-md">
        <mat-label>Min Bag Weight (lb)</mat-label>
        <input
          matInput
          formControlName="minBagWeight"
          [appDigitWithTwoDecimalNumber]="7"
          (blur)="checkMinMaxBagWeights('minBagWeight')"
          (input)="
            checkMinMaxBagWeights('minBagWeight'); dispatchFormValues()
          " />
        <app-svg-icon
          *ngIf="minBagWeightError"
          matSuffix
          [iconName]="actionIconsList.infoCircle"
          [isStaticSvg]="true"
          [isFrom]="svgIconFromList.page"
          class="cursor"
          (click)="fetchWidth($event, 'manifest-minBagWeight')"
          (keydown)="$event.stopPropagation()"></app-svg-icon>
        <app-custom-error-tooltip
          *ngIf="
            minBagWeightError &&
            setManifestCriteriaErrorTooltip['manifest-minBagWeight']
          "
          [tooltipWidth]="setManifestCriteriaWidth['manifest-minBagWeight']"
          [tooltipText]="!minBagWeightError ? '' : minmaxBagWeightErrorMessage"
          (tooltipCloseClicked)="
            commonService.closeErrorTooltip(
              'manifest-minBagWeight',
              setManifestCriteriaErrorTooltip
            )
          ">
        </app-custom-error-tooltip>
      </mat-form-field>
      <div>-</div>
      <mat-form-field appearance="outline" class="mat-md">
        <mat-label>Max Bag Weight (lb)</mat-label>
        <input
          matInput
          formControlName="maxBagWeight"
          [appDigitWithTwoDecimalNumber]="7"
          (blur)="checkMinMaxBagWeights('maxBagWeight')"
          (input)="
            checkMinMaxBagWeights('maxBagWeight'); dispatchFormValues()
          " />
        <app-svg-icon
          *ngIf="maxBagWeightError"
          matSuffix
          [iconName]="actionIconsList.infoCircle"
          [isStaticSvg]="true"
          [isFrom]="svgIconFromList.page"
          class="cursor"
          (click)="fetchWidth($event, 'manifest-maxBagWeight')"
          (keydown)="$event.stopPropagation()"></app-svg-icon>
        <app-custom-error-tooltip
          *ngIf="
            maxBagWeightError &&
            setManifestCriteriaErrorTooltip['manifest-maxBagWeight']
          "
          [tooltipWidth]="setManifestCriteriaWidth['manifest-maxBagWeight']"
          [tooltipText]="!maxBagWeightError ? '' : minmaxBagWeightErrorMessage"
          (tooltipCloseClicked)="
            commonService.closeErrorTooltip(
              'manifest-maxBagWeight',
              setManifestCriteriaErrorTooltip
            )
          ">
        </app-custom-error-tooltip>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="mat-md">
        <mat-label>Min Shipment Value ($)</mat-label>
        <input
          matInput
          formControlName="minHawbValue"
          [appDigitWithTwoDecimalNumber]="17"
          (blur)="checkMinMaxHawbValues('minHawbValue')"
          (input)="
            checkMinMaxHawbValues('minHawbValue'); dispatchFormValues()
          " />
        <app-svg-icon
          *ngIf="minHawbValueError"
          matSuffix
          [iconName]="actionIconsList.infoCircle"
          [isStaticSvg]="true"
          [isFrom]="svgIconFromList.page"
          class="cursor"
          (click)="fetchWidth($event, 'manifest-minHawbValue')"
          (keydown)="$event.stopPropagation()"></app-svg-icon>
        <app-custom-error-tooltip
          *ngIf="
            minHawbValueError &&
            setManifestCriteriaErrorTooltip['manifest-minHawbValue']
          "
          [tooltipWidth]="setManifestCriteriaWidth['manifest-minHawbValue']"
          [tooltipText]="!minHawbValueError ? '' : minmaxHawbErrorMessage"
          (tooltipCloseClicked)="
            commonService.closeErrorTooltip(
              'manifest-minHawbValue',
              setManifestCriteriaErrorTooltip
            )
          ">
        </app-custom-error-tooltip>
      </mat-form-field>
      <div>-</div>
      <mat-form-field appearance="outline" class="mat-md">
        <mat-label>Max Shipment Value ($)</mat-label>
        <input
          matInput
          formControlName="maxHawbValue"
          [appDigitWithTwoDecimalNumber]="17"
          (blur)="checkMinMaxHawbValues('maxHawbValue')"
          (input)="
            checkMinMaxHawbValues('maxHawbValue'); dispatchFormValues()
          " />
        <app-svg-icon
          *ngIf="maxHawbValueError"
          matSuffix
          [iconName]="actionIconsList.infoCircle"
          [isStaticSvg]="true"
          [isFrom]="svgIconFromList.page"
          class="cursor"
          (click)="fetchWidth($event, 'manifest-maxHawbValue')"
          (keydown)="$event.stopPropagation()"></app-svg-icon>
        <app-custom-error-tooltip
          *ngIf="
            maxHawbValueError &&
            setManifestCriteriaErrorTooltip['manifest-maxHawbValue']
          "
          [tooltipWidth]="setManifestCriteriaWidth['manifest-maxHawbValue']"
          [tooltipText]="!maxHawbValueError ? '' : minmaxHawbErrorMessage"
          (tooltipCloseClicked)="
            commonService.closeErrorTooltip(
              'manifest-maxHawbValue',
              setManifestCriteriaErrorTooltip
            )
          ">
        </app-custom-error-tooltip>
      </mat-form-field>
    </div>
  </div>
</div>
