import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateUtilService {
  constructor() {}

  public formatDateTimeZone(date: Date | null): string {
    if (!date) {
      return '';
    }
    if (
      date.getHours() === 0 &&
      date.getMinutes() === 0 &&
      date.getSeconds() === 0
    ) {
      date.setHours(new Date().getHours());
      date.setMinutes(new Date().getMinutes());
      date.setSeconds(new Date().getSeconds());
    }
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    const timezoneAbbreviation = this.getTimezoneAbbreviation();
    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds} ${timezoneAbbreviation}`;
  }

  public getTimezoneAbbreviation(): string {
    const offset = new Date().getTimezoneOffset();
    const sign = offset > 0 ? '-' : '+';
    const absOffset = Math.abs(offset);
    const hours = Math.floor(absOffset / 60);
    const minutes = absOffset % 60;
    const formattedOffset = `${sign}${('0' + hours).slice(-2)}:${(
      '0' + minutes
    ).slice(-2)}`;
    switch (formattedOffset) {
      case '-05:00':
        return 'EST'; // Eastern Standard Time
      case '-06:00':
        return 'CST'; // Central Standard Time
      case '-07:00':
        return 'MST'; // Mountain Standard Time
      case '-08:00':
        return 'PST'; // Pacific Standard Time
      case '-09:00':
        return 'AKST'; // Alaska Standard Time
      case '-10:00':
        return 'HST'; // Hawaii-Aleutian Standard Time
      case '+05:30':
        return 'IST'; // Indian Standard Time
      default:
        return '';
    }
  }
}
