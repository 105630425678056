import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormConfig } from 'src/app/shared/models/dynamic.model';
import { ValidatorService } from 'src/app/shared/services/form-control-validators.service';
import { miscellaneousFormfieldsConfig } from '../../bag.config';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { SaveBagMiscellaneous } from '../../store/bag.action';
import { IBagMiscellaneousData } from 'src/app/features/model/bag.model';
import {
  ActionIcons,
  ModalPopupMessages,
  ModeIcons,
  pageType,
} from 'src/app/shared/utility/constants';

@Component({
  selector: 'app-miscellaneous',
  templateUrl: './miscellaneous.component.html',
  styleUrl: './miscellaneous.component.scss',
})
export class MiscellaneousComponent implements OnInit {
  public bagMiscellaneousData$!: Observable<IBagMiscellaneousData>;
  public miscellaneousForm!: FormGroup;
  public miscellaneousFieldsJsonConfig!: FormConfig;
  public pageMode!: string;
  public misInfo = ModalPopupMessages.bagMiscellaneous;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formUtilsService: ValidatorService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private store: Store
  ) {
    this.pageMode = ModeIcons.add;
    this.bagMiscellaneousData$ = this.store.select(
      state => state.bagMiscellaneous.miscellaneous
    );
  }

  ngOnInit(): void {
    this.miscellaneousFieldsJsonConfig = miscellaneousFormfieldsConfig;
    this.createForm();
    this.route.queryParamMap.subscribe(params => {
      if (params.get(ModeIcons.mode) === ActionIcons.bulkEdit) {
        this.pageMode = ModeIcons.bulkEdit;
      } else if (params.get(ModeIcons.mode) === ActionIcons.edit) {
        this.pageMode = ModeIcons.edit;
      } else if (
        params.get(ModeIcons.mode) === ActionIcons.view ||
        params.get(ModeIcons.mode) === ActionIcons.bulkview
      ) {
        this.pageMode = ModeIcons.view;
      }
    });
    this.dataFetch();
    this.commonService.miscellaneousInvalid
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === true) {
          this.miscellaneousForm.markAllAsTouched();
        }
      });
    this.getPageMode();
  }

  public createForm() {
    const { form } = this.formUtilsService.generateForm(
      this.miscellaneousFieldsJsonConfig
    );
    this.miscellaneousForm = form;
  }

  public dispatchMiscellaneousFormValueChanges() {
    this.store.dispatch(new SaveBagMiscellaneous(this.miscellaneousForm.value));
    this.commonService.checkPristineAccordionData(
      this.miscellaneousForm.pristine
    );
  }

  private dataFetch() {
    this.commonService.savedBagData
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === pageType.dataBind) {
          this.fetchOldValues();
        }
      });
  }

  private fetchOldValues() {
    this.bagMiscellaneousData$.subscribe((data: any) => {
      this.miscellaneousForm.patchValue(data);
    });
  }

  private getPageMode() {
    this.commonService.isBagPageMode
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: string) => {
        if (this.commonService.isValidField(data)) {
          this.pageMode = data;
          if (this.pageMode === ModeIcons.view) {
            this.miscellaneousForm.disable();
          }
        }
      });
  }
}
