<div class="ibc-summary-table" id="print-section">
  <mat-table [dataSource]="dataSource">
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-divider></mat-divider>
    <ng-container>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </ng-container>

    <ng-container
      *ngFor="let item of summaryCustomiseTableColumns; let i = index">
      <ng-container
        [matColumnDef]="item.key"
        *ngIf="
          commonTableService.displaySelectedColumnsToTable(
            item.key,
            summaryCustomiseTableColumns,
            displayedColumns
          )
        ">
        <mat-header-cell *matHeaderCellDef cdkDrag>
          <span class="column-header cursor">
            <span class="ibc-body-text-large">{{ item.label }}</span>
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span
            class="{{
              commonTableService.isHyperlink(item.label, isFrom)
                ? 'text-hyperlink'
                : ''
            }}">
            {{
              item.key !== 'date'
                ? element[item.key]
                : (element.date | date: dateFormat)
            }}
          </span>
        </mat-cell>
      </ng-container>
    </ng-container>
  </mat-table>
</div>
