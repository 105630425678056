import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicSearchComponent } from './basic-search/basic-search.component';
import { MaterialModule } from '../../material.module';
import { SummaryTableComponent } from '../tables/summary-table/summary-table.component';
import { SearchOptionsOverlayComponent } from './search-options/search-options-overlay/search-options-overlay.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { SearchPopupComponent } from './search-options/search-popup/search-popup.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { KeyStrokeDirectivesModule } from '../../directives/keystroke/keystrokes.module';
import { RecentSavedSearchPopupComponent } from './search-options/recent-saved-search-popup/recent-saved-search-popup.component';
import { CustomErrorTooltipComponent } from '../custom-error-tooltip/custom-error-tooltip.component';

@NgModule({
  declarations: [
    BasicSearchComponent,
    SummaryTableComponent,
    SearchOptionsOverlayComponent,
    RecentSavedSearchPopupComponent,
    SearchPopupComponent,
  ],
  exports: [BasicSearchComponent, SummaryTableComponent, SvgIconComponent],
  imports: [
    CommonModule,
    MaterialModule,
    SvgIconComponent,
    DragDropModule,
    KeyStrokeDirectivesModule,
    CustomErrorTooltipComponent,
  ],
})
export class SearchModule {}
