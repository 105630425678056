<div class="mega-menu">
  <div class="recent-pinned-search-container">
    <div class="recent-search-container">
      <div class="icon-container">
        <app-svg-icon
          [iconName]="'recentSearch'"
          [isFrom]="'Page'"
          [isStaticSvg]="true">
        </app-svg-icon>
      </div>

      <div class="recent-searches">
        <div class="ibc-body-text-large">Recent Searches</div>
        <div class="search-list ibc-body-link-text-small">
          <ng-container
            *ngFor="let item of requiredRecentSearches; let i = index">
            <div class="recent-search-items-actions">
              <span
                *ngIf="i <= 8"
                class="cursor"
                [matTooltip]="item.savedSearchName"
                matTooltipPosition="right"
                (click)="onhyperlinkClicked(item)"
                (keydown)="$event.stopPropagation()"
                [attr.aria-hidden]="true">
                {{ item.savedSearchName }}
              </span>
              <div *ngIf="i <= 8" class="recent-list-action-icons">
                <ng-container
                  *ngFor="let actionIcons of savedSearchTableActionIcons">
                  <app-svg-icon
                    class="cursor {{
                      actionIcons.name === recentListPinIcon && !item.isPinned
                        ? 'isDisableActionIcon'
                        : ''
                    }}"
                    [iconName]="actionIcons.name"
                    [isFrom]="'Page'"
                    [isStaticSvg]="true"
                    (click)="onActionClicked(actionIcons, item)"
                    (keydown)="$event.stopPropagation()">
                  </app-svg-icon>
                </ng-container>
              </div>
            </div>
            <span
              *ngIf="i === 9"
              (click)="openRecentAndSavedSearchPopup(false)"
              class="cursor view-more-recent-list"
              (keydown)="$event.stopPropagation()"
              [attr.aria-hidden]="true">
              View more...
              <app-svg-icon
                [iconName]="'notesShortcut'"
                [isStaticSvg]="true"
                [isFrom]="'Page'"
                class="see-more-icon"
                (keydown)="$event.stopPropagation()"></app-svg-icon>
            </span>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="pinned-search-container">
      <div class="icon-container">
        <app-svg-icon
          [iconName]="'pinnedSearch'"
          [isFrom]="'Page'"
          [isStaticSvg]="true">
        </app-svg-icon>
      </div>
      <div class="pinned-searches">
        <div class="ibc-body-text-large">Pinned Searches</div>
        <div class="search-list pinned-search-list ibc-body-link-text-small">
          <ng-container *ngFor="let item of pinnedSearchList; let i = index">
            <span
              class="cursor"
              [matTooltip]="item.savedSearchName"
              matTooltipPosition="above"
              (click)="onhyperlinkClicked(item)"
              (keydown)="$event.stopPropagation()"
              [attr.aria-hidden]="true">
              {{ item.savedSearchName }}
            </span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="search-actions">
    <button
      *ngFor="let item of overlayButtons"
      class="ibc-medium-button ibc-secondary-default-button"
      [ngClass]="item.id === 2 && !searchFieldValue ? '' : 'cursor'"
      (click)="
        item.id === 1
          ? openRecentAndSavedSearchPopup(true)
          : searchService.openSaveAsNewSearchPopup(
              true,
              isFrom,
              searchFieldValue
            )
      "
      [disabled]="item.id === 2 && !searchFieldValue">
      <span class="ibc-body-text-regular">{{ item.name }}</span>
    </button>
  </div>
</div>
