import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormConfig } from 'src/app/shared/models/dynamic.model';
import { searchPopupFieldsConfig } from '../../search.config';
import { ValidatorService } from 'src/app/shared/services/form-control-validators.service';
import { FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import {
  ActionIcons,
  ModalPopupMessages,
  SvgIconFrom,
} from 'src/app/shared/utility/constants';
import { SearchService } from '../../search.service';

@Component({
  selector: 'app-search-popup',
  templateUrl: './search-popup.component.html',
  styleUrl: './search-popup.component.scss',
})
export class SearchPopupComponent implements OnInit {
  public searchPopupData: any;
  public searchPopupJsonConfig!: FormConfig;
  public searchPopupForm!: FormGroup;
  public actionIconsList = ActionIcons;
  public svgIconFromList = SvgIconFrom;
  public setSearchPopupWidth: any = {};
  public setSearchPopupErrorTooltip: any = {};
  public setSearchPopupTooltipEvent: any = {};

  constructor(
    public commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<SearchPopupComponent>,
    private formUtilsService: ValidatorService,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.searchPopupData = this.data;
    this.searchPopupJsonConfig = searchPopupFieldsConfig;
    this.createForm();
    this.bindData();
  }

  public createForm() {
    const { form } = this.formUtilsService.generateForm(
      this.searchPopupJsonConfig
    );
    this.searchPopupForm = form;
  }

  public checkControlHasError(controlName: string) {
    return (
      this.searchPopupForm.get(controlName)?.touched &&
      this.searchPopupForm.get(controlName)?.errors
    );
  }

  public save() {
    if (this.searchPopupData.title === ModalPopupMessages.pinSearch) {
      const savePinSearchPayload = {
        name: this.searchPopupForm.get('name')?.value,
        description: this.searchPopupForm.get('description')?.value,
        savedSearchIds: [this.searchPopupForm.get('searchId')?.value],
        action: ActionIcons.pinSearch,
        tagName: this.searchPopupData?.code?.data?.isFrom,
      };
      this.searchService.saveUpdatePinSearches(savePinSearchPayload, this.ref);
    } else if (
      this.searchPopupData.title === ModalPopupMessages.saveAsNewSearchHeader ||
      this.searchPopupData.title === ModalPopupMessages.updateSearch
    ) {
      const saveNewSearchPayload = [
        {
          name: this.searchPopupForm.get('name')?.value,
          description: this.searchPopupForm.get('description')?.value,
          searchId: this.searchPopupForm.get('searchId')?.value,
          isPinned: this.searchPopupData?.code?.data?.isPinned,
          tagName: this.searchPopupData?.code?.data?.isFrom,
        },
      ];
      this.searchService.saveUpdateNewSearches(
        saveNewSearchPayload,
        this.ref,
        this.searchPopupData.title === ModalPopupMessages.updateSearch
      );
    }
  }

  public closePopup() {
    this.ref.close();
  }

  private bindData() {
    this.searchPopupForm
      .get('name')
      ?.setValue(this.searchPopupData?.code?.data?.searchName);
    this.searchPopupForm
      .get('description')
      ?.setValue(this.searchPopupData?.code?.data?.description);
    this.searchPopupForm
      .get('searchId')
      ?.setValue(this.searchPopupData?.code?.data?.searchId);
  }
}
