import {
  MawbBasicSearchResultsPayload,
  FlightList,
  IMawbDetailsData,
  IMawbManifestData,
  IMawbManifestListData,
  IMawbMiscellaneousData,
  IFlightMiscellaneousData,
  IMawbInformationData,
} from 'src/app/features/model/mawb.model';

export class FetchMawbsList {
  static readonly type = '[Mawbs] List';
  constructor(public payload: MawbBasicSearchResultsPayload) {}
}
export class ResetMawbsList {
  static readonly type = '[Mawbs] Reset Mawbs List';
}
export class FetchMawbsInformationListSuccess {
  static readonly type = '[Mawb] Fetch Mawbs Information List Success';
  constructor(public mawbs: IMawbInformationData[]) {}
}

export class FetchSelectedMawbById {
  static readonly type = '[Mawbs] Fetch Selected Mawb By Id';
  constructor(public mawbId: number) {}
}

export class ResetMawbsInformationList {
  static readonly type = '[Mawbs] Reset Mawbs Information List';
}

export class SaveMawbDetails {
  static readonly type = '[Details] Mawb Save';
  constructor(public payload: IMawbDetailsData) {}
}

export class SaveMawbMiscellaneous {
  static readonly type = '[Miscellaneous] Mawb Save';
  constructor(public payload: IMawbMiscellaneousData) {}
}

export class LinkManifestToMawb {
  static readonly type = '[Manifest] Link';
  constructor(public payload: IMawbManifestData[]) {}
}

export class AddFlight {
  static readonly type = '[Flight] Add Flight';
  constructor(public payload: FlightList) {}
}

export class UpdateFlight {
  static readonly type = '[Flight] Update Flight';
  constructor(public payload: FlightList) {}
}

export class DeleteFlight {
  static readonly type = '[Flight] Delete Flight';
  constructor(public payload: number) {}
}
export class ResetFlight {
  static readonly type = '[Flight] Reset Flight';
}
export class AddMawbManifest {
  static readonly type = '[Manifest] Add Mawb Manifest';
  constructor(public payload: IMawbManifestListData[]) {}
}
export class DeleteMawbManifest {
  static readonly type = '[Manifest] Unlink Mawb Manifest';
  constructor(public payload: any) {}
}

export class ResetMawbManifests {
  static readonly type = '[Manifest] Reset Mawb Manifests';
}
export class ResetMawbManifestList {
  static readonly type = '[Manifest] Reset Mawb Manifest';
}

export class UnlinkManifestFromMawb {
  static readonly type = '[Manifest] Unlink From Mawb';
  constructor(public payload: string) {}
}
export class ResetMawbMiscellaneous {
  static readonly type = '[Miscellaneous] Mawb Reset';
}

export class SaveFlightMiscellaneous {
  static readonly type = '[Miscellaneous] Flight Save';
  constructor(public payload: IFlightMiscellaneousData) {}
}

export class RemoveUnsavedFlightMiscellaneous {
  static readonly type = '[Miscellaneous Flight] Remove Unsaved';
}
export class ResetFlightMiscellaneous {
  static readonly type = '[Miscellaneous Flight] Reset';
}