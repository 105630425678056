import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { IAttachmentStateModel } from 'src/app/shared/models/dynamic.model';
import {
  AddAttachments,
  DeleteAttachment,
  DeleteTempAttachment,
  RemoveUnsavedAttachments,
  ResetAtatchmnetCodes,
} from './attachments.action';
import { pageType } from 'src/app/shared/utility/constants';

@State<IAttachmentStateModel>({
  name: 'attachment',
  defaults: {
    attachmentList: [],
  },
})
@Injectable()
export class AttachmentState {
  @Action(AddAttachments)
  addAttachments(
    ctx: StateContext<IAttachmentStateModel>,
    { payload }: AddAttachments
  ) {
    const state = ctx.getState();
    const updatedAttachmentList = state.attachmentList.map(
      (attachment: any) => {
        if (
          attachment.attachmentId === payload.attachmentId &&
          attachment.id === payload.id
        ) {
          if (attachment.isFromScreen === 'piece') {
            return { ...attachment, isSavedData: payload.isSavedData };
          } else if (attachment.isFromScreen === 'commodity') {
            return { ...attachment, isSavedData: payload.isSavedData };
          }
        }
        return attachment;
      }
    );

    const existingAttachmentIndex = updatedAttachmentList.findIndex(
      (attachment: any) =>
        attachment.attachmentId === payload.attachmentId &&
        attachment.id === payload.id &&
        (attachment.isFromScreen === 'piece' ||
          attachment.isFromScreen === 'commodity' ||
          attachment.isFromScreen === 'hawb' ||
          attachment.isFromScreen === 'bag')
    );

    if (existingAttachmentIndex === -1) {
      updatedAttachmentList.push(payload);
    }
    const newState = {
      ...state,
      attachmentList: updatedAttachmentList,
    };
    ctx.setState(newState);
  }

  @Action(DeleteAttachment)
  deleteAttachment(
    ctx: StateContext<IAttachmentStateModel>,
    { attachmentId }: DeleteAttachment
  ) {
    const state = ctx.getState();
    const updatedAttachmentList = state.attachmentList.map(
      (attachment: any) => {
        if (attachment.id === attachmentId) {
          return { ...attachment, action: 'd' };
        }
        return attachment;
      }
    );

    const newState = {
      ...state,
      attachmentList: updatedAttachmentList,
    };
    ctx.setState(newState);
  }

  @Action(DeleteTempAttachment)
  deleteTempAttachment(
    ctx: StateContext<IAttachmentStateModel>,
    { attachmentId }: DeleteTempAttachment
  ) {
    const state = ctx.getState();
    const updatedAttachmentList = state.attachmentList.filter(
      attachment => attachment.id !== attachmentId
    );
    const newState = {
      ...state,
      attachmentList: updatedAttachmentList,
    };
    ctx.setState(newState);
  }

  @Action(RemoveUnsavedAttachments)
  removeUnsavedAttachments(ctx: StateContext<IAttachmentStateModel>) {
    const state = ctx.getState();
    const updatedAttachmentList = state.attachmentList.filter(
      (attachment: any) =>
        attachment.isSavedData ||
        (attachment.isFromScreen !== pageType.piece &&
          attachment.isFromScreen !== pageType.commodity &&
          pageType.mawbFlight)
    );

    const newState = {
      ...state,
      attachmentList: updatedAttachmentList,
    };
    ctx.setState(newState);
  }

  @Action(ResetAtatchmnetCodes)
  resetAtatchmentCodes(ctx: StateContext<IAttachmentStateModel>) {
    const state = ctx.getState();
    const newState = {
      ...state,
      attachmentList: [],
    };
    ctx.setState(newState);
  }
}
