import { IAttachments } from 'src/app/shared/models/dynamic.model';

export class AddAttachments {
  static readonly type = '[Attachment Codes] Add Attachment Code';
  constructor(public payload: IAttachments) {}
}

export class DeleteAttachment {
  static readonly type = '[Attachment] Delete Attachment';
  constructor(public attachmentId: string) {}
}

export class DeleteTempAttachment {
  static readonly type = '[Attachment] Delete Temp Attachment';
  constructor(public attachmentId: string) {}
}
export class RemoveUnsavedAttachments {
  static readonly type = '[Attachment] Remove Unsaved Attachments';
}

export class ResetAtatchmnetCodes {
  static readonly type = '[Attachment Codes] Reset Attachment Codes';
}
