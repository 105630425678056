<div class="miscellaneous-form-container">
  <div class="mawb-misc-form" [formGroup]="mawbMiscForm">
    <mat-form-field appearance="outline" class="mat-md">
      <mat-label>US CBP ECCF 86 Entry Number</mat-label>
      <input
        matInput
        formControlName="usCbpEccf86EntryNumber"
        (input)="dispatchMiscellaneousMawbFormValueChanges()" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="mat-md">
      <mat-label>Clearance Agent</mat-label>
      <input
        matInput
        formControlName="clearanceAgent"
        (input)="dispatchMiscellaneousMawbFormValueChanges()" />
    </mat-form-field>
  </div>
  <app-information-note
    [message]="miscInfoText"
    *ngIf="miscInfoText"></app-information-note>
</div>
