import { Injectable } from '@angular/core';
import { State, Action, Store } from '@ngxs/store';
import {
  HawbInfoScreenStateModel,
  defaultConsigneeShipping,
  defaultHawbInformation,
  defaultMiscellaneous,
} from 'src/app/features/model/hawb.model';
import {  UpdateMainConsignee, UpdateMainShipper } from './hawb.state';
import {
  ResetCommodity,
  ResetDispCodes,
  ResetEvents,
  ResetHawbInfo,
  ResetMiscellaneousCommodity,
  ResetPODCodes,
  ResetPiece,
  ResetRoles,
  UpdateHawbInformation,
  UpdateMiscellaneous,
} from './hawb.action';
import { ResetNotesSection } from 'src/app/shared/components/notes/store/notes.action';
import { ResetAtatchmnetCodes } from 'src/app/shared/components/attachments/store/attachments.action';

// Reset action
export class ResetAllStates {
  static readonly type = '[Global] Reset All States';
}

export class ResetAllPaginationStates {
  static readonly type = '[Global] Reset All Pagination States';
}

export class ResetSharedStates {
  static readonly type = '[Global] Reset Shared States';
}

@State<HawbInfoScreenStateModel>({
  name: 'resetStates',
  defaults: {
    data: defaultHawbInformation,
  },
})
@Injectable()
export class ResetAllState {
  constructor(private store: Store) {}

  @Action(ResetAllStates)
  resetAllStates() {
    this.store.dispatch(new ResetRoles());
    this.store.dispatch(new ResetDispCodes());
    this.store.dispatch(new ResetPODCodes());
    this.store.dispatch(new UpdateMiscellaneous(defaultMiscellaneous));
    this.store.dispatch(new UpdateHawbInformation(defaultHawbInformation));
    this.store.dispatch(new ResetMiscellaneousCommodity());
    this.store.dispatch(new ResetPiece());
    this.store.dispatch(new ResetCommodity());
    this.store.dispatch(new ResetEvents());
    this.store.dispatch(new ResetHawbInfo());
    this.store.dispatch(new UpdateMainConsignee(defaultConsigneeShipping.consignee));
    this.store.dispatch(new UpdateMainShipper(defaultConsigneeShipping.shipper));
  }

  @Action(ResetAllPaginationStates)
  resetAllPaginationStates() {
    this.store.dispatch(new ResetRoles());
    this.store.dispatch(new ResetDispCodes());
    this.store.dispatch(new ResetPODCodes());
    this.store.dispatch(new UpdateMiscellaneous(defaultMiscellaneous));
    this.store.dispatch(new UpdateHawbInformation(defaultHawbInformation));
    this.store.dispatch(new ResetMiscellaneousCommodity());
    this.store.dispatch(new ResetPiece());
    this.store.dispatch(new ResetCommodity());
    this.store.dispatch(new ResetEvents());
    this.store.dispatch(new UpdateMainConsignee(defaultConsigneeShipping.consignee));
    this.store.dispatch(new UpdateMainShipper(defaultConsigneeShipping.shipper));
  }

  @Action(ResetSharedStates)
  clearSharedStates() {
    this.store.dispatch(new ResetNotesSection());
    this.store.dispatch(new ResetAtatchmnetCodes());
  }
}
