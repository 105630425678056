<div class="message-popup-wrapper">
  <div class="message-popup-header">
    <div class="ibc-body-text-large-bold">{{ messagePopupData.title }}</div>
    <div class="card-action-icons">
      <app-svg-icon
        [iconName]="'close'"
        class="cursor"
        [isStaticSvg]="true"
        [isFrom]="'Popup'"
        (staticIconClicked)="closePopup()"></app-svg-icon>
    </div>
  </div>

  <div
    class="message-popup-content {{
      messagePopupData.code?.isForDownload
        ? 'pb-20'
        : !messagePopupData.code?.isMultiple &&
            messagePopupData.code?.isOptionalTextbox
          ? 'pb-0'
          : ''
    }}">
    <div
      class="ibc-body-text-medium confirmation-message"
      *ngIf="messagePopupData.code?.message">
      {{ messagePopupData.code?.message }}
    </div>
    <ng-container *ngIf="messagePopupData.code?.isMultiple">
      <div>
        <div
          class="ibc-body-text-medium confirmation-text"
          *ngIf="messagePopupData.code?.message !== ''">
          {{ deleteText }}
        </div>
        <mat-form-field
          appearance="outline"
          class="mat-md w-full input-wrapper-none">
          <input
            matInput
            type="text"
            [(ngModel)]="confirmValue"
            placeholder="Type here" />
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        !messagePopupData.code?.isMultiple &&
        messagePopupData.code?.isOptionalTextbox
      ">
      <mat-form-field
        appearance="outline"
        class="mat-md w-full hawb-description-textarea">
        <mat-label>Notes*</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="2"
          data-shortcut-number="8"
          maxlength="500"
          [(ngModel)]="confirmValue"
          placeholder="Reason for blocking"
          (input)="commentBoxChange($event)">
        </textarea>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="messagePopupData.code?.isForDownload">
      <div class="ibc-body-text-large-bold confirmation-message">
        {{ popupMessages.downloadFileText }}
      </div>
      <mat-radio-group [(ngModel)]="fileType" class="download-file">
        <mat-radio-button value="csv">
          <span class="ibc-body-text-medium"> CSV </span>
        </mat-radio-button>
        <mat-radio-button value="excel">
          <span class="ibc-body-text-medium"> Excel </span>
        </mat-radio-button>
      </mat-radio-group>
    </ng-container>

    <ng-container *ngIf="messagePopupData.code?.isForPrint">
      <div class="ibc-body-text-large-bold confirmation-message">
        {{ popupMessages.printColumnSelection }}
      </div>
      <div class="checkbox-columns">
        <ng-container *ngFor="let list of summaryTableColumns; let i = index">
          <div>
            <mat-checkbox
              [checked]="list.isSelected"
              (click)="handleCheckboxSelection(i, list.isSelected)"
              (keydown)="$event.stopPropagation()">
            </mat-checkbox>
            <span class="ibc-body-text-regular">{{ list.label }}</span>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <div class="popup-footer" *ngIf="messagePopupData.code?.isFooterRequired">
    <button
      class="cursor ibc-medium-button {{
        messagePopupData.code?.isBulk
          ? 'ibc-caution-secondary-button'
          : !messagePopupData.code?.isMultiple &&
              messagePopupData.code?.isOptionalTextbox
            ? 'ibc-caution-secondary-button'
            : 'ibc-secondary-default-button'
      }}"
      (click)="closePopup()">
      <span class="ibc-body-text-regular">{{ getCancelButtonName() }}</span>
    </button>
    <ng-container *ngIf="messagePopupData.code?.isForDownload">
      <button
        class="ibc-medium-button ibc-primary-default-button"
        [ngClass]="!fileType ? '' : 'cursor'"
        (click)="confirm()"
        [disabled]="!fileType">
        <span class="ibc-body-text-regular">
          {{ popupButtons.Download }}
        </span>
      </button>
    </ng-container>
    <ng-container *ngIf="messagePopupData.code?.isForPrint">
      <button
        class="ibc-medium-button ibc-primary-default-button"
        [ngClass]="isPrintDisable ? '' : 'cursor'"
        (click)="confirm()"
        [disabled]="isPrintDisable">
        <span class="ibc-body-text-regular">
          {{ popupButtons.Print }}
        </span>
      </button>
    </ng-container>
    <ng-container
      *ngIf="
        !messagePopupData.code?.isForDownload &&
        !messagePopupData.code?.isForPrint
      ">
      <button
        class="ibc-medium-button {{
          messagePopupData.code?.isBulk
            ? 'ibc-caution-default-button'
            : !messagePopupData.code?.isMultiple &&
                messagePopupData.code?.isOptionalTextbox
              ? 'ibc-caution-default-button ibc-body-text-regular'
              : 'ibc-primary-default-button'
        }}"
        [ngClass]="
          messagePopupData.code?.isMultiple &&
          confirmValue !== popupButtons.Delete
            ? ''
            : 'cursor'
        "
        *ngIf="!isDeleteAction"
        (click)="confirm()"
        [disabled]="
          (messagePopupData.code?.isMultiple &&
            confirmValue !== popupButtons.Delete) ||
          (!messagePopupData.code?.isMultiple &&
            messagePopupData.code?.isOptionalTextbox &&
            !confirmValue)
        ">
        <span class="ibc-body-text-regular">
          {{
            messagePopupData.code?.isMultiple ||
            messagePopupData.code?.isForSearch
              ? popupButtons.Delete
              : messagePopupData.code?.isForDiscardChanges
                ? popupButtons.Discard
                : !messagePopupData.code?.isMultiple &&
                    messagePopupData.code?.isOptionalTextbox
                  ? messagePopupData.code?.isBlock
                  : popupButtons.Yes
          }}
        </span>
      </button>
      <button
        class="ibc-medium-button {{
          messagePopupData.code?.isBulk
            ? 'ibc-caution-default-button'
            : 'ibc-primary-default-button'
        }}"
        [ngClass]="
          messagePopupData.code?.isMultiple &&
          confirmValue !== popupButtons.Delete
            ? ''
            : 'cursor'
        "
        *ngIf="isDeleteAction"
        (click)="confirm()"
        [disabled]="
          messagePopupData.code?.isMultiple &&
          normalizeText(confirmValue) !== normalizeText(requiredBulkDeleteText)
        ">
        <span class="ibc-body-text-regular">{{ popupButtons.Delete }}</span>
      </button>
    </ng-container>
  </div>
</div>
