import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BaseService } from '../../core/services/base.httpService';
import { HttpSignatures } from 'src/app/shared/utility/constants';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  url = '';
  constructor(public baseService: BaseService) {}

  getUserInfo(): Observable<any> {
    this.url = 'http://localhost:3000/v1/users/test/test'; //'https://api.publicapis.org/entries'
    return this.baseService
      .initiateApiRequest(HttpSignatures['Get'], this.url, '', '', 'json')
      .pipe();
  }

  getUserList(): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve([{ userId: 1234, Name: 'Test001' }]);
      }, 1000);
    });
  }
}
