import { NgModule } from '@angular/core';
import {
  FocusOnEscapeDirective,
  TabFocusOnIndividualCheckboxDirective,
  TableFocusCheckboxShortcutDirective,
  PaginatorKeyCombinationDirective,
  PaginatorNavigationDirective,
  IconClickShortcutDirective,
  FocusOnShortcutDirective,
  AccordionExpandCollapseDirective,
  TabShortcutDirective,
  OpenPopupShortcutDirective,
  OpenDetailsShortcutDirective,
  HawbShortcutDirective,
  SortShortcutDirective,
  TabNoOnlyShortcutDirective,
} from './keystroke.directive';
import { AppEnterSubmitDirective } from './enter-submit.directive';

@NgModule({
  declarations: [
    FocusOnEscapeDirective,
    TabFocusOnIndividualCheckboxDirective,
    TableFocusCheckboxShortcutDirective,
    PaginatorKeyCombinationDirective,
    PaginatorNavigationDirective,
    IconClickShortcutDirective,
    FocusOnShortcutDirective,
    AccordionExpandCollapseDirective,
    TabShortcutDirective,
    OpenPopupShortcutDirective,
    OpenDetailsShortcutDirective,
    HawbShortcutDirective,
    SortShortcutDirective,
    TabNoOnlyShortcutDirective,
    AppEnterSubmitDirective,
  ],
  exports: [
    FocusOnEscapeDirective,
    TabFocusOnIndividualCheckboxDirective,
    TableFocusCheckboxShortcutDirective,
    PaginatorKeyCombinationDirective,
    PaginatorNavigationDirective,
    IconClickShortcutDirective,
    FocusOnShortcutDirective,
    AccordionExpandCollapseDirective,
    TabShortcutDirective,
    OpenPopupShortcutDirective,
    OpenDetailsShortcutDirective,
    HawbShortcutDirective,
    SortShortcutDirective,
    TabNoOnlyShortcutDirective,
    AppEnterSubmitDirective,
  ],
})
export class KeyStrokeDirectivesModule {}
