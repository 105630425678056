export interface IErrorResponse {
  statusCode: number;
  message: string;
  status?: number;
}

export interface IDropdownResponse {
  id: any;
  name: string;
  number?: string;
  accnt_id?: any;
}

export interface FieldOption {
  label: string;
  value: string;
}

export interface FormField {
  id: string;
  type: string;
  label: string;
  required?: boolean;
  pattern?: string;
  minValue?: number;
  maxValue?: number;
  options?: FieldOption[];
  patternError?: string;
  requiredError?: string;
  isDisableField?: boolean;
  allowNumeric?: boolean;
  noInitialWhiteSpace?: boolean;
  allowAlphabet?: boolean;
  allowAlphaNumeric?: boolean;
  allowAlphabetWithSpace?: boolean;
  allowAlphaNumericWithSpace?: boolean;
  minError?: string;
  emailPatternError?: string;
  phonePatternError?: string;
  isCheckForMaxValue?: boolean;
}

export interface FormConfig {
  fields: FormField[];
}

export interface SvgIconProp {
  name: string;
  isDisabled: boolean;
  width: number;
  height: number;
  order?: number;
}

export interface SvgIcon {
  path: string;
  disabled: boolean;
  iconClass: string;
  width: number;
  height: number;
  pathAdditional?: string;
  pathAdditional1?: string;
  toolTip: string;
  name: string;
  order?: number;
  rect?: {
    x: number;
    y: number;
    width: number;
    height: number;
    rx?: number;
    fill?: string;
  };
}

export interface StaticSvgIconProp {
  name: string;
  width: number;
  height: number;
  iconClass: string;
  pathClass: string;
  totalPath: number;
  path: string[];
  rect?: {
    x: number;
    y: number;
    width: number;
    height: number;
    rx?: number;
    fill?: string;
  };
}

export interface ILookupDropdownResponse {
  statusCode: number;
  message: string;
  data: IDropdownResponse[];
  code: number;
}

export interface IGridColumnElement {
  label: string;
  key: string;
}

export interface INotesDetail {
  id: string | number;
  comments: string;
  action: string;
  isFromScreen?: string;
  notesId?: string | null;
  userId?: 0;
  userName?: string;
  facility?: string;
  date?: string;
  dateTime?: string;
  type?: string;
  source?: string;
  isEditable?: boolean;
  isDisable?: boolean;
  isSaved?: boolean;
}

export interface INotesSectionData {
  ibc: INotesDetail[];
  customer: INotesDetail[];
}

export interface IAttachments {
  id?: string;
  type: string;
  description: string;
  fileName: string;
  signedUrl?: string;
  action?: string;
  isInputRow?: boolean;
  isFromScreen?: string;
  attachmentId?: string | null;
  isSavedData?: boolean;
}

export interface IAttachmentStateModel {
  attachmentList: IAttachments[];
}

export interface EventList {
  id: string;
  date: string;
  time: string;
  zone: string;
  name: string;
  info: string;
}

export interface IEventsStateModel {
  events: EventList[];
}

export class Claim {
  id: number | undefined;
  claim: string | undefined;
  value: string | undefined;
}

export interface Widget {
  id: number;
  title: string;
  value: string;
  extraClasses: string;
  iconName: string;
  name: string;
}

export interface DropdownList {
  id: number;
  name: string;
}

export interface TableColumns {
  key: string;
  label: string;
  value: string;
  isSelected: boolean;
  defaultView: boolean;
  isEditable?: boolean;
}

export interface RecentSearch {
  searchName: string;
}

export interface SavedSearch {
  savedSearchId: number;
  savedSearchName: string;
  description: string;
  createdDate: string;
  checked?: boolean;
}

export interface MawbMock {
  mawb: string;
  origin: string;
  destination: string;
  agent_code: string;
  airline_code: string;
  clearance_type: string;
}

export interface IExcelTableData {
  name: string;
  headers: string[];
  headerKeys: string[];
  tableData: any[];
  selectedFileType: string;
  successMessage: string;
  isFromSummary: boolean;
}

export interface IExcelInfoPageData {
  name: string;
  sectionNames: string[];
  sectionHeaderKeys: string[];
  pageData: any;
  selectedFileType: string;
  successMessage: string;
  isFromSummary: boolean;
  extOrNumber: string;
}

export interface IBasicSearchProps {
  paging: IBasicSearchPaginationProps;
  searchText: string;
}

export interface IBasicSearchPaginationProps {
  fromIndex: number;
  toIndex: number;
  sortingOrder: string;
  sortingField: string;
  searchText?: string;
}

export interface AdvancedSearchTextOpearatorsExamples {
  exampleHeader: string;
  example: string;
}

export interface AdvancedSearchTextOperators {
  operatorName: string;
  operatorPurpose: string;
  examples: AdvancedSearchTextOpearatorsExamples[];
}

export interface HawbMiscellaneousProps {
  title: string;
  content: string;
}

export interface SectionObject {
  data: any[];
  headers: string[];
  headerKeys: string[];
}