import {
  BagSearchPayload,
  IBagCriteriaData,
  IBagInfoData,
  IBagInformationData,
  IBagMiscellaneousData,
  IBagPieceData,
  IBagPieceListData,
} from 'src/app/features/model/bag.model';

// Criteria Section
export class SaveBagCriteria {
  static readonly type = '[Criteria] Save';
  constructor(public payload: IBagCriteriaData) {}
}

export class ResetBagCriteria {
  static readonly type = '[Criteria] Reset';
}

// Miscellaneous Section
export class SaveBagMiscellaneous {
  static readonly type = '[Miscellaneous] Save';
  constructor(public payload: IBagMiscellaneousData) {}
}

export class ResetBagMiscellaneous {
  static readonly type = '[Miscellaneous] Reset';
}

//Piece table
export class LinkPieceToBag {
  static readonly type = '[Piece] Link';
  constructor(public payload: IBagPieceData[]) {}
}

export class UnlinkPieceFromBag {
  static readonly type = '[Piece] Unlink';
  constructor(public payload: string) {}
}

export class ResetPieceList {
  static readonly type = '[Piece] Reset';
}


export class FetchBagsList {
  static readonly type = '[Bags] Summary List';
  constructor(public payload: BagSearchPayload) {}
}

export class ResetBagsList {
  static readonly type = '[Bags] Reset Summary List';
}
export class FetchBagsInformationListSuccess {
  static readonly type = '[Bag] Fetch Bags Information List Success';
  constructor(public bags: IBagInformationData[]) {}
}

export class FetchSelectedBagById {
  static readonly type = '[Bags] Fetch Selected Bag By Id';
  constructor(public bagId: number) {}
}

export class ResetBagsInformationList {
  static readonly type = '[Bags] Reset Bags Information List';
}

export class UpdateBagInformation {
  static readonly type = '[BagInformation] Update';
  constructor(public payload: IBagInfoData) {}
}

export class AddBagPiece {
  static readonly type = '[Piece] Add Bag Piece';
  constructor(public payload: IBagPieceListData[]) {}
}
export class DeleteBagPiece {
  static readonly type = '[Piece] Unlink Bag Piece';
  constructor(public payload: any) {}
}
export class ResetBagPieceList {
  static readonly type = '[Piece] Reset Bag Piece';
}
