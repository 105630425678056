import { Injectable } from '@angular/core';
import { Action, State, Store } from '@ngxs/store';
import { ResetAtatchmnetCodes } from 'src/app/shared/components/attachments/store/attachments.action';
import { ResetEvents } from 'src/app/shared/components/events/store/events.action';
import { ResetNotesSection } from 'src/app/shared/components/notes/store/notes.action';
import {
  ResetFlight,
  ResetMawbManifestList,
  ResetMawbManifests,
  ResetMawbMiscellaneous,
  SaveMawbMiscellaneous,
} from './mawb.action';
import { defaultMawbInformationData } from '../mawb.config';
import { MawbInfoResetStateModel } from 'src/app/features/model/mawb.model';

export class MawbResetAllStates {
  static readonly type = '[Mawb] Reset All States';
}

export class ResetSharedStates {
  static readonly type = '[Global] Reset Shared States';
}

@State<MawbInfoResetStateModel>({
  name: 'mawbResetStates',
  defaults: {
    data: defaultMawbInformationData(),
  },
})
@Injectable()
export class MawbResetAllState {
  constructor(private store: Store) {}

  @Action(MawbResetAllStates)
  resetAllStates() {
    this.store.dispatch(new ResetEvents());
    this.store.dispatch(new ResetMawbManifestList());
    this.store.dispatch(new ResetMawbMiscellaneous());
    this.store.dispatch(new ResetFlight());
    this.store.dispatch(new ResetMawbManifests());
    this.store.dispatch(new SaveMawbMiscellaneous({}));
  }

  @Action(ResetSharedStates)
  clearSharedStates() {
    this.store.dispatch(new ResetNotesSection());
    this.store.dispatch(new ResetAtatchmnetCodes());
  }
}
