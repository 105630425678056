import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValidatorService } from 'src/app/shared/services/form-control-validators.service';
import {
  ModalPopupMessages,
  ModeIcons,
  pageType,
} from 'src/app/shared/utility/constants';
import { miscellaneousFormFieldsConfig } from '../../mawb.config';
import { SaveMawbMiscellaneous } from '../../store/mawb.action';
import { Store } from '@ngxs/store';
import { CommonService } from 'src/app/shared/services/common.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { IMawbMiscellaneousData } from 'src/app/features/model/mawb.model';

@Component({
  selector: 'app-miscellaneous',
  templateUrl: './miscellaneous.component.html',
  styleUrl: './miscellaneous.component.scss',
})
export class MiscellaneousComponent {
  public miscInfoText = ModalPopupMessages.mawbMiscellaneousMessage;
  public mawbMiscForm!: FormGroup;
  public pageMode = ModeIcons.add;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public mawbMiscellaneousData$!: Observable<IMawbMiscellaneousData>;

  constructor(
    private formUtilsService: ValidatorService,
    private store: Store,
    private commonService: CommonService
  ) {
    this.createForm();
    this.checkMode();
    this.mawbMiscellaneousData$ = this.store.select(
      state => state.mawbMiscellaneous.miscellaneous
    );
  }

  ngOnInit() {
    this.dataFetch();
  }

  public createForm() {
    const { form } = this.formUtilsService.generateForm(
      miscellaneousFormFieldsConfig
    );
    this.mawbMiscForm = form;
  }

  public dispatchMiscellaneousMawbFormValueChanges() {
    this.mawbMiscForm.value.usCbpEccf86EntryNumber = this.mawbMiscForm.value
      .usCbpEccf86EntryNumber
      ? +this.mawbMiscForm.value.usCbpEccf86EntryNumber
      : null;
    this.store.dispatch(new SaveMawbMiscellaneous(this.mawbMiscForm.value));
    this.commonService.checkPristineAccordionData(this.mawbMiscForm.pristine);
  }

  private checkMode() {
    this.commonService.isCheckForMode
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.pageMode = data ?? ModeIcons.add;
        if (this.pageMode === ModeIcons.view) {
          this.mawbMiscForm.disable();
        } else {
          this.mawbMiscForm.enable();
        }
      });
  }

  private dataFetch() {
    this.commonService.savedBagData
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === pageType.dataBind) {
          this.fetchOldValues();
        }
      });
  }

  private fetchOldValues() {
    this.mawbMiscellaneousData$.subscribe((data: any) => {
      this.mawbMiscForm.patchValue(data);
    });
  }
}
