import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import {
  actionIcons,
  keyStrokes,
  searchDataParam,
} from '../../utility/constants';

@Directive({
  selector: '[appEnterSubmit]',
})
export class AppEnterSubmitDirective {
  @Input() appEnterSubmit!: string;

  constructor(private el: ElementRef) {}

  @HostListener('document:keydown', ['$event'])
  onEnterKeyPress(event: KeyboardEvent) {
    if (event.key === keyStrokes.enter) {
      let preventDefault = false;
      let iconClass: string | null = null;
      switch (this.appEnterSubmit) {
        case searchDataParam.summary:
          iconClass = actionIcons.filter;
          break;
        case searchDataParam.information:
          iconClass = actionIcons.save;
          break;
        case searchDataParam.basicSearch:
          iconClass = searchDataParam.basicSearch;
          break;
        default:
          preventDefault = true;
          break;
      }
      this.triggerClick(event, iconClass, preventDefault);
    }
  }

  private triggerClick(
    event: KeyboardEvent,
    iconClass: string | null,
    preventDefault: boolean
  ) {
    if (iconClass && !preventDefault) {
      const iconElement = this.el.nativeElement.querySelector(
        `.${iconClass}`
      ) as HTMLElement;
      if (iconElement) {
        const iconStyle = window.getComputedStyle(iconElement);
        if (iconStyle.getPropertyValue('pointer-events') === 'none') {
          event.preventDefault();
          return;
        }
      }
      if (iconElement) {
        iconElement.click();
      }
      event.preventDefault();
    }
  }
}
