import {
  FlexibleConnectedPositionStrategyOrigin,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { SearchOptionsOverlayComponent } from '../components/search/search-options/search-options-overlay/search-options-overlay.component';
import { OverlayPositions } from '../utility/constants';
import { Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchOverlayService {
  private searchOverlayRef!: OverlayRef;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private overlay: Overlay
  ) {}

  public openSearchOverlay(
    searchOverlay: FlexibleConnectedPositionStrategyOrigin,
    isFromModule: string
  ) {
    this.getOverlayConfig(searchOverlay);
    const tooltipPortal = new ComponentPortal(SearchOptionsOverlayComponent);
    const tooltipRef = this.searchOverlayRef.attach(tooltipPortal);
    tooltipRef.instance.isFrom = isFromModule;
    tooltipRef.instance.hyperlinkClicked
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.closeOverlay();
      });
  }

  public closeOverlay() {
    this.searchOverlayRef?.detach();
  }

  private getOverlayConfig(
    searchOverlay: FlexibleConnectedPositionStrategyOrigin
  ) {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(searchOverlay)
      .withPositions([
        {
          originX: OverlayPositions.end,
          originY: OverlayPositions.top,
          overlayX: OverlayPositions.end,
          overlayY: OverlayPositions.top,
          offsetX: 0,
          offsetY: 10,
        },
      ]);
    this.searchOverlayRef = this.overlay.create({
      positionStrategy,
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }
}
