import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseService } from '../core/services/base.httpService';
import { ErrorHandlerService } from './services/error-handler.service';
import { IntercepterService } from './services/intercepter.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    BaseService,
    ErrorHandlerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IntercepterService,
      multi: true,
    },
  ],
})
export class CoreModule {}
