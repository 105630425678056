import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CheckboxStateService {
  public updateCheckboxStateForCurrentPage(
    dataSource: any,
    selection: SelectionModel<any>,
    globalSelectedIds: Set<number | string>,
    idProperty: string
  ): void {
    const currentPageData = dataSource.data;
    currentPageData.forEach((row: any) => {
      if (globalSelectedIds.has(row[idProperty])) {
        selection.select(row);
      }
    });
  }

  public updateHeaderCheckboxState(
    dataSource: any,
    isChecked: (row: any) => boolean,
    isAllSelected: boolean
  ): { globalHeaderCheckboxState: boolean; indeterminate: boolean } {
    const currentPageData = dataSource.data;
    const checkedCount = currentPageData.filter(isChecked).length;
    const newGlobalHeaderCheckboxState =
      isAllSelected ||
      (checkedCount === currentPageData.length && checkedCount > 0);
    const indeterminate =
      !isAllSelected &&
      checkedCount > 0 &&
      checkedCount < currentPageData.length;
    return {
      globalHeaderCheckboxState: newGlobalHeaderCheckboxState,
      indeterminate,
    };
  }
}
