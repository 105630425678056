import { EventList } from 'src/app/shared/models/dynamic.model';

export class AddEvents {
  static readonly type = '[Events] Add';
  constructor(public payload: EventList[]) {}
}

export class ResetEvents {
  static readonly type = '[Events] Reset Events';
}
