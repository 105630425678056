import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import {
  INotesDetail,
  INotesSectionData,
} from 'src/app/shared/models/dynamic.model';
import {
  AddCustomerNotes,
  AddIBCNotes,
  DeleteCustomerNotes,
  DeleteIBCNotes,
  ResetNotesSection,
  UpdateCustomerNotes,
  UpdateIBCNotes,
} from './notes.action';

@State<INotesSectionData>({
  name: 'notes',
  defaults: {
    ibc: [],
    customer: [],
  },
})
@Injectable()
export class NotesSectionState {
  @Action(AddIBCNotes)
  addIBCNotes(ctx: StateContext<INotesSectionData>, { payload }: AddIBCNotes) {
    const state = ctx.getState();
    const newState = {
      ...state,
      ibc: [...state.ibc, payload],
    };
    ctx.setState(newState);
  }

  @Action(AddCustomerNotes)
  addCustomerNotes(
    ctx: StateContext<INotesSectionData>,
    { payload }: AddCustomerNotes
  ) {
    const state = ctx.getState();
    const newState = {
      ...state,
      customer: [...state.customer, payload],
    };
    ctx.setState(newState);
  }

  @Action(UpdateIBCNotes)
  updateIBCNotes(
    ctx: StateContext<INotesSectionData>,
    { payload }: UpdateIBCNotes
  ) {
    const state = ctx.getState();
    const updatedIBCData = state.ibc.map((ibcNote: INotesDetail) => {
      if (
        ibcNote.id == payload.id &&
        ibcNote.isFromScreen == payload.isFromScreen
      ) {
        return { ...ibcNote, ...payload };
      } else {
        return ibcNote;
      }
    });
    ctx.setState({
      ...state,
      ibc: updatedIBCData,
    });
  }

  @Action(UpdateCustomerNotes)
  updateCustomerNotes(
    ctx: StateContext<INotesSectionData>,
    { payload }: UpdateCustomerNotes
  ) {
    const state = ctx.getState();
    const updatedCustomerData = state.customer.map(
      (customerNote: INotesDetail) => {
        if (
          customerNote.id == payload.id &&
          customerNote.isFromScreen == payload.isFromScreen
        ) {
          return { ...customerNote, ...payload };
        } else {
          return customerNote;
        }
      }
    );
    ctx.setState({
      ...state,
      customer: updatedCustomerData,
    });
  }

  @Action(DeleteIBCNotes)
  deleteIBCNotes(
    ctx: StateContext<INotesSectionData>,
    { payload }: DeleteIBCNotes
  ) {
    const state = ctx.getState();
    const updatedIBCData = state.ibc.filter(
      ibcNote =>
        ibcNote.id !== payload.id ||
        ibcNote.isFromScreen !== payload.isFromScreen
    );
    const newState = {
      ...state,
      ibc: updatedIBCData,
    };
    ctx.setState(newState);
  }

  @Action(DeleteCustomerNotes)
  deleteCustomerNotes(
    ctx: StateContext<INotesSectionData>,
    { payload }: DeleteCustomerNotes
  ) {
    const state = ctx.getState();
    const updatedCustomerData = state.customer.filter(
      customerNote =>
        customerNote.id !== payload.id ||
        customerNote.isFromScreen !== payload.isFromScreen
    );
    const newState = {
      ...state,
      customer: updatedCustomerData,
    };
    ctx.setState(newState);
  }

  @Action(ResetNotesSection)
  resetNoteSection(ctx: StateContext<INotesSectionData>) {
    ctx.setState({
      ibc: [],
      customer: [],
    });
  }
}
