<div class="notification-snackbar-container">
  <div class="icon-text-container">
    <app-svg-icon
      class="snackbar-icon"
      [iconName]="iconClassName"
      [isStaticSvg]="true"
      [isFrom]="'Snackbar'">
    </app-svg-icon>
    <div class="ibc-heading-h7-subtitle">{{ data.message }}</div>
  </div>
  <app-svg-icon
    class="cursor dismiss-icon"
    [iconName]="'close'"
    [isStaticSvg]="true"
    [isFrom]="'Snackbar'"
    (staticIconClicked)="dismiss()"
    *ngIf="!isButton">
  </app-svg-icon>
  <button
    mat-flat-button
    *ngIf="isButton"
    class="ibc-snakbar-ok-button"
    (click)="dismiss()">
    OK
  </button>
</div>
