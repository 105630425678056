import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-snakbar',
  template: ` <div class="custom-snackbar-container">
    <span>{{ data.message }}</span>
    <button
      mat-icon-button
      class="cursor custom-snackbar-close-button"
      (click)="dismiss()">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2109_42457)">
          <mask
            id="mask0_2109_42457"
            style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="48"
            height="48">
            <rect width="48" height="48" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2109_42457)">
            <path
              d="M6.27461 18.5251L5.47461 17.7251L11.1996 12.0001L5.47461 6.2751L6.27461 5.4751L11.9996 11.2001L17.7246 5.4751L18.5246 6.2751L12.7996 12.0001L18.5246 17.7251L17.7246 18.5251L11.9996 12.8001L6.27461 18.5251Z"
              fill="#922269" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_2109_42457">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  </div>`,
  styles: [
    `
      .custom-snackbar-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        color: white;
        font-family: 'Roboto';
        letter-spacing: 0.5px;
      }

      .custom-snackbar-close-button {
        color: #fff;
      }
    `,
  ],
})
export class SnakbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<SnakbarComponent>
  ) {}

  dismiss(): void {
    this.snackBarRef.dismiss();
  }
}
