export const GridEventColumns = [
  {
    key: 'date',
    label: 'Event Date, Time, Time Zone',
  },
  {
    key: 'name',
    label: 'Events',
  },
  {
    key: 'info',
    label: 'Details',
  },
];

export interface EventsColumnData {
  date: string;
  info: string;
  name: string;
  id?: string;
}
