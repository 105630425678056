<div class="popup-wrapper">
  <div class="popup-header">
    <div class="ibc-body-text-large-bold">
      {{
        popupData?.code?.isFromSavedPopup ? 'Saved Searches' : 'Recent Searches'
      }}
    </div>
    <div class="card-action-icons">
      <app-svg-icon
        class="cursor"
        [iconName]="'close'"
        [isStaticSvg]="true"
        [isFrom]="'Popup'"
        (click)="closePopup()"
        (keydown)="$event.stopPropagation()"></app-svg-icon>
    </div>
  </div>

  <div class="popup-content">
    <div class="table-global-actions-container">
      <app-svg-icon
        [list]="searchPopupHeaderIcons"
        class="search-modal-action-icons"
        (iconClicked)="onIconClicked($event)">
      </app-svg-icon>
    </div>
    <div class="saved-search-table">
      <mat-table
        [dataSource]="dataSource"
        matSort
        matSortDisableClear
        class="ibc-summary-table">
        <mat-header-row
          *matHeaderRowDef="searchPopupTableColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: searchPopupTableColumns"></mat-row>

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="onCheckAllChange($event); getSelectionStatus()"
              [checked]="selection.hasValue() && selectAllChecked"
              [indeterminate]="selection.hasValue() && !selectAllChecked">
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="
                $event.checked ? checked(row) : unchecked(row);
                getSelectionStatus();
                setSelectedAll()
              "
              [checked]="isChecked(row)"
              onkeypress="$event.stopPropagation()">
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container *ngFor="let item of requiredTableColumns; let i = index">
          <ng-container [matColumnDef]="item.key">
            <mat-header-cell *matHeaderCellDef>
              <span class="column-header cursor">
                <span class="ibc-body-text-large">{{ item.label }}</span>
                <span
                  mat-sort-header
                  #sortIcon
                  *ngIf="dataSource.data.length !== 0">
                  <app-svg-icon
                    [iconName]="'sort'"
                    [isStaticSvg]="true"
                    [isFrom]="'Page'"
                    [condition]="tableSortDirection"></app-svg-icon>
                </span>
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container
                *ngIf="item.label === searchLabel; else defaultColumnContent">
                <span
                  class="text-hyperlink ibc-body-link-text-medium"
                  [matTooltip]="element[item.key]"
                  matTooltipPosition="right"
                  (click)="onhyperlinkClicked(element)"
                  (keydown)="$event.stopPropagation()"
                  [attr.aria-hidden]="true">
                  {{ element[item.key] }}
                </span>
              </ng-container>
              <ng-template #defaultColumnContent>
                <span class="ibc-body-text-medium'">
                  {{ element[item.key] }}
                </span>
              </ng-template>
            </mat-cell>
          </ng-container>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>
            <span class="ibc-body-text-large">Actions</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="actions-icons">
              <div
                *ngFor="let actionIcons of savedSearchTableActionIcons"
                class="search-option-icons">
                <app-svg-icon
                  [iconName]="actionIcons.name"
                  [isFrom]="'Page'"
                  [isStaticSvg]="true"
                  class="saved-search-table-actions {{
                    !element.checked ? 'cursor' : ''
                  }} {{
                    (actionIcons.name === recentSearchActionPinIcon &&
                      !element.isPinned) ||
                    element.checked
                      ? 'disabled'
                      : ''
                  }}"
                  (click)="
                    element.checked
                      ? $event.stopPropagation()
                      : tableActionClicked(actionIcons, element)
                  "
                  (keydown)="$event.stopPropagation()">
                </app-svg-icon>
              </div>
            </div>
          </mat-cell>
        </ng-container>
      </mat-table>

      <ng-container *ngIf="dataSource.data.length === 0">
        <div class="ibc-grid-message initial-search-text">
          {{ noRecentSavedSearchMessage }}
        </div>
      </ng-container>
    </div>
  </div>

  <div class="popup-footer">
    <button
      class="cursor ibc-medium-button ibc-secondary-default-button"
      (click)="closePopup()">
      <span class="ibc-body-text-regular">Cancel</span>
    </button>
  </div>
</div>
