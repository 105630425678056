import { INotesDetail } from 'src/app/shared/models/dynamic.model';

export class AddIBCNotes {
  static readonly type = '[Notes] Add IBC Notes';
  constructor(public payload: INotesDetail) {}
}

export class AddCustomerNotes {
  static readonly type = '[Notes] Add Customer Notes';
  constructor(public payload: INotesDetail) {}
}

export class UpdateIBCNotes {
  static readonly type = '[Notes] Update IBC Notes';
  constructor(public payload: INotesDetail) {}
}

export class UpdateCustomerNotes {
  static readonly type = '[Notes] Update Customer Notes';
  constructor(public payload: INotesDetail) {}
}

export class DeleteIBCNotes {
  static readonly type = '[Notes] Delete IBC Notes';
  constructor(public payload: INotesDetail) {}
}

export class DeleteCustomerNotes {
  static readonly type = '[Notes] Delete Customer Notes';
  constructor(public payload: INotesDetail) {}
}

export class DeleteBagPieceIBCNotes {
  static readonly type = '[Notes] Delete IBC Notes';
  constructor(public payload: string | number) {}
}

export class DeleteBagPieceCustomerNotes {
  static readonly type = '[Notes] Delete Customer Notes';
  constructor(public payload: string | number) {}
}

export class ResetNotesSection {
  static readonly type = '[Notes] Reset Notes';
}

export class RemoveUnsavedNotesSection {
  static readonly type = '[Notes] Remove Unsaved Notes';
  constructor(public payload: INotesDetail) {}
}
