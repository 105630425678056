import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appSymbolInput]',
})
export class AddSymbolInputDirective {
  constructor(
    private el: ElementRef,
    private control: NgControl
  ) {}

  @HostListener('focus', ['$event.target'])
  onFocus(target: HTMLInputElement) {
    if (this.control && !this.control.value?.startsWith('+')) {
      this.control.control?.setValue('+');
    }
  }

  @HostListener('input', ['$event.target'])
  onInput(target: HTMLInputElement) {
    const maxLength = 11; // Including the "+" sign
    if (!this.control) return;

    let value = this.control.value || ''; // Ensure value is not null
    value = value.replace(/\D/g, ''); // Remove non-digit characters

    // Ensure "+" is at the beginning of the input
    if (!value.startsWith('+')) {
      value = '+' + value;
    }

    // Limit the total length to 11 characters
    if (value.length > maxLength) {
      value = value.slice(0, maxLength);
    }

    // Update the value in the control
    this.control.control?.setValue(value);
  }
}
