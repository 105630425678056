<div class="popup-wrapper">
  <div class="popup-header">
    <div class="ibc-body-text-large-bold">
      {{ searchPopupData.title }}
    </div>
    <div class="card-action-icons">
      <app-svg-icon
        [iconName]="'close'"
        class="cursor"
        [isStaticSvg]="true"
        [isFrom]="'Popup'"
        (staticIconClicked)="closePopup()"></app-svg-icon>
    </div>
  </div>

  <div class="save-as-new-search-form-container" [formGroup]="searchPopupForm">
    <mat-form-field appearance="outline" class="mat-md">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" data-shortcut-number="1" />
      <app-svg-icon
        *ngIf="checkControlHasError('name')"
        matSuffix
        [iconName]="actionIconsList.infoCircle"
        [isStaticSvg]="true"
        [isFrom]="svgIconFromList.page"
        class="cursor"
        (click)="
          commonService.setErrorTooltipData(
            $event,
            'name',
            this.setSearchPopupWidth,
            this.setSearchPopupErrorTooltip,
            this.setSearchPopupTooltipEvent
          )
        "
        (keydown)="$event.stopPropagation()"></app-svg-icon>
      <app-custom-error-tooltip
        *ngIf="
          checkControlHasError('name') && setSearchPopupErrorTooltip['name']
        "
        [tooltipWidth]="setSearchPopupWidth['name']"
        [tooltipText]="'Name is required'"
        (tooltipCloseClicked)="
          commonService.closeErrorTooltip('name', setSearchPopupErrorTooltip)
        ">
      </app-custom-error-tooltip>
    </mat-form-field>
    <mat-form-field appearance="outline" class="mat-lg">
      <mat-label>Description</mat-label>
      <textarea
        matInput
        formControlName="description"
        cdkTextareaAutosize
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="2">
      </textarea>
    </mat-form-field>
  </div>

  <div class="popup-footer">
    <button
      class="cursor ibc-medium-button ibc-secondary-default-button"
      (click)="closePopup()">
      <span class="ibc-body-text-regular">Cancel</span>
    </button>
    <button
      class="ibc-medium-button ibc-primary-default-button"
      [disabled]="!searchPopupForm.valid"
      (click)="save()">
      <span class="ibc-body-text-regular">Save</span>
    </button>
  </div>
</div>
