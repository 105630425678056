import { Component, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  public isOverlayOpened!: boolean;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private commonService: CommonService) {
    this.commonService.checkOverlayOpened
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.isOverlayOpened = data;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
