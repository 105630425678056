import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ResetAllStates } from 'src/app/features/components/hawb/store/reset.states';
import { Store } from '@ngxs/store';
import { CommonService } from './common.service';
import { ResetHawbsList } from 'src/app/features/components/hawb/store/hawb.action';
import { ResetManifestsList } from 'src/app/features/components/manifest/store/manifest.action';
import {
  AddBagPiece,
  ResetBagsList,
  UpdateBagInformation,
} from 'src/app/features/components/bag/store/bag.action';
import { BagResetAllStates } from 'src/app/features/components/bag/store/reset.states';
import { defaultBagInfo } from 'src/app/features/model/bag.model';
import { ManifestResetAllStates } from 'src/app/features/components/manifest/store/reset.states';
import { MawbResetAllStates } from 'src/app/features/components/mawb/store/mawb.reset';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  constructor(
    private router: Router,
    private store: Store,
    private commonService: CommonService
  ) {
    this.listenToRouterEvents();
  }

  public listenToRouterEvents() {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        if (
          !event.url.includes('hawb/hawb-information') &&
          !event.url.includes('mawb/mawb-information')
        ) {
          this.store.dispatch(new ResetAllStates());
          this.commonService.getMode('');
          localStorage.setItem('PieceListTempId', '');
        } else if (!event.url.includes('hawb')) {
          this.store.dispatch(new ResetHawbsList());
        } else {
          localStorage.setItem('PieceList', '');
          localStorage.setItem('CommodityList', '');
        }
        if (!event.url.includes('bag/bag-information')) {
          this.store.dispatch(new BagResetAllStates());
          this.commonService.getBagPageMode('');
          this.store.dispatch(new UpdateBagInformation(defaultBagInfo));
          this.store.dispatch(new AddBagPiece([]));
        } else if (!event.url.includes('bag')) {
          this.store.dispatch(new ResetBagsList());
          localStorage.setItem('PieceList', '');
        } else if (!event.url.includes('manifest')) {
          this.store.dispatch(new ResetManifestsList());
        }
        if (!event.url.includes('manifest/manifest-information')) {
          this.store.dispatch(new ManifestResetAllStates());
        }
        if (!event.url.includes('mawb/mawb-information')) {
          this.store.dispatch(new MawbResetAllStates());
        }
      });
  }
}
