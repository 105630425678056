import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-information-note',
  standalone: true,
  imports: [],
  templateUrl: './information-note.component.html',
  styleUrl: './information-note.component.scss',
})
export class InformationNoteComponent {
  @Input() message: any;
}
