import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'app-custom-error-tooltip',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './custom-error-tooltip.component.html',
  styleUrl: './custom-error-tooltip.component.scss',
})
export class CustomErrorTooltipComponent {
  @Output() tooltipCloseClicked = new EventEmitter<boolean>();
  @Input() tooltipText!: string;
  @Input() tooltipWidth!: string;

  public dismiss(): void {
    this.tooltipCloseClicked.emit(true);
  }
}
