<div class="miscellaneous-section">
  <div [formGroup]="miscellaneousForm">
    <div class="first-miscellaneous-container">
      <mat-form-field appearance="outline" class="mat-md">
        <mat-label>Tare Weight (lb)</mat-label>
        <input
          matInput
          type="text"
          formControlName="tareWeight"
          [appDigitWithTwoDecimalNumber]="7"
          (focusout)="dispatchMiscellaneousFormValueChanges()" />
      </mat-form-field>
    </div>
  </div>

  <app-information-note
    [message]="misInfo"
    *ngIf="misInfo"></app-information-note>
</div>
