<div class="criteria-section">
  <div [formGroup]="criteriaForm">
    <div class="first-criteria-container">
      <mat-form-field appearance="outline" class="mat-md">
        <mat-label>Agent</mat-label>
        <input
          matInput
          type="text"
          formControlName="agent"
          [matAutocomplete]="agentAutoComplete"
          (focusout)="
            dispatchCriteriaFormValueChanges();
            formAutocompleteValueChanges(
              'agent',
              'agenttypeVal',
              agentTypeData
            );
            clearErrors($event, 'agent')
          "
          placeholder="Enter Agent Code or Name" />
        <mat-autocomplete
          #agentAutoComplete="matAutocomplete"
          (optionSelected)="dispatchCriteriaFormValueChanges()">
          <mat-option
            [value]="item.name"
            *ngFor="let item of filteredAgentOptions | async">
            {{ item.name }}
          </mat-option>
        </mat-autocomplete>
        <app-svg-icon
          *ngIf="checkControlHasError('agent')"
          matSuffix
          [iconName]="actionIconsList.infoCircle"
          [isStaticSvg]="true"
          [isFrom]="svgIconFromList.page"
          class="cursor"
          (click)="fetchWidth($event, 'agent')"
          (keydown)="$event.stopPropagation()"></app-svg-icon>
        <app-custom-error-tooltip
          *ngIf="
            checkControlHasError('agent') && setBagCriteriaErrorTooltip['agent']
          "
          [tooltipWidth]="setBagCriteriaWidth['agent']"
          [tooltipText]="isAutocompleteError('agent')"
          (tooltipCloseClicked)="
            commonService.closeErrorTooltip('agent', setBagCriteriaErrorTooltip)
          ">
        </app-custom-error-tooltip>
      </mat-form-field>

      <mat-form-field appearance="outline" class="mat-md">
        <mat-label>Shipment Final Destination</mat-label>
        <input
          matInput
          type="text"
          formControlName="finalDestinationId"
          [matAutocomplete]="finalDestinationIdAutoComplete"
          (focusout)="
            dispatchCriteriaFormValueChanges();
            formAutocompleteValueChanges(
              'finalDestinationId',
              'finalDestinationIdVal',
              finalDestinationData
            );
            clearErrors($event, 'finalDestinationId')
          " />
        <mat-autocomplete
          #finalDestinationIdAutoComplete="matAutocomplete"
          (optionSelected)="dispatchCriteriaFormValueChanges()">
          <mat-option
            [value]="item.name"
            *ngFor="let item of filteredDestinationOptions | async">
            {{ item.name }}
          </mat-option>
        </mat-autocomplete>
        <app-svg-icon
          *ngIf="checkControlHasError('finalDestinationId')"
          matSuffix
          [iconName]="actionIconsList.infoCircle"
          [isStaticSvg]="true"
          [isFrom]="svgIconFromList.page"
          class="cursor"
          (click)="fetchWidth($event, 'finalDestinationId')"
          (keydown)="$event.stopPropagation()"></app-svg-icon>
        <app-custom-error-tooltip
          *ngIf="
            checkControlHasError('finalDestinationId') &&
            setBagCriteriaErrorTooltip['finalDestinationId']
          "
          [tooltipWidth]="setBagCriteriaWidth['finalDestinationId']"
          [tooltipText]="isAutocompleteError('finalDestinationId')"
          (tooltipCloseClicked)="
            commonService.closeErrorTooltip(
              'finalDestinationId',
              setBagCriteriaErrorTooltip
            )
          ">
        </app-custom-error-tooltip>
      </mat-form-field>

      <mat-form-field appearance="outline" class="mat-md">
        <mat-label>Facility Type</mat-label>
        <input
          matInput
          type="text"
          formControlName="finalFacility"
          [matAutocomplete]="finalFacilityIdAutoComplete"
          (focusout)="
            dispatchCriteriaFormValueChanges();
            formAutocompleteValueChanges(
              'finalFacility',
              'finalFacilityVal',
              finalFacilityData
            );
            clearErrors($event, 'finalFacility')
          " />
        <mat-autocomplete
          #finalFacilityIdAutoComplete="matAutocomplete"
          (optionSelected)="dispatchCriteriaFormValueChanges()">
          <mat-option
            [value]="item.name"
            *ngFor="let item of filteredFinalFacilityOptions | async">
            {{ item.name }}
          </mat-option>
        </mat-autocomplete>
        <app-svg-icon
          *ngIf="checkControlHasError('finalFacility')"
          matSuffix
          [iconName]="actionIconsList.infoCircle"
          [isStaticSvg]="true"
          [isFrom]="svgIconFromList.page"
          class="cursor"
          (click)="fetchWidth($event, 'finalFacility')"
          (keydown)="$event.stopPropagation()"></app-svg-icon>
        <app-custom-error-tooltip
          *ngIf="
            checkControlHasError('finalFacility') &&
            setBagCriteriaErrorTooltip['finalFacility']
          "
          [tooltipWidth]="setBagCriteriaWidth['finalFacility']"
          [tooltipText]="isAutocompleteError('finalFacility')"
          (tooltipCloseClicked)="
            commonService.closeErrorTooltip(
              'finalFacility',
              setBagCriteriaErrorTooltip
            )
          ">
        </app-custom-error-tooltip>
      </mat-form-field>

      <mat-form-field appearance="outline" class="mat-md">
        <mat-label>Airline</mat-label>
        <input
          matInput
          type="text"
          formControlName="airline"
          [matAutocomplete]="airlineCodeAutoComplete"
          (focusout)="
            dispatchCriteriaFormValueChanges();
            formAutocompleteValueChanges(
              'airline',
              'airlineVal',
              airlineCodeData
            );
            clearErrors($event, 'airline')
          "
          placeholder="Enter Airline Code or Name" />
        <mat-autocomplete
          #airlineCodeAutoComplete="matAutocomplete"
          (optionSelected)="dispatchCriteriaFormValueChanges()">
          <mat-option
            [value]="item.name"
            *ngFor="let item of filteredAirlineCodeOptions | async">
            {{ item.name }}
          </mat-option>
        </mat-autocomplete>
        <app-svg-icon
          *ngIf="checkControlHasError('airline')"
          matSuffix
          [iconName]="actionIconsList.infoCircle"
          [isStaticSvg]="true"
          [isFrom]="svgIconFromList.page"
          class="cursor"
          (click)="fetchWidth($event, 'airline')"
          (keydown)="$event.stopPropagation()"></app-svg-icon>
        <app-custom-error-tooltip
          *ngIf="
            checkControlHasError('airline') &&
            setBagCriteriaErrorTooltip['airline']
          "
          [tooltipWidth]="setBagCriteriaWidth['airline']"
          [tooltipText]="isAutocompleteError('airline')"
          (tooltipCloseClicked)="
            commonService.closeErrorTooltip(
              'airline',
              setBagCriteriaErrorTooltip
            )
          ">
        </app-custom-error-tooltip>
      </mat-form-field>
    </div>

    <div class="second-criteria-container">
      <mat-form-field appearance="outline" class="mat-md">
        <mat-label>Clearance Type</mat-label>
        <input
          matInput
          type="text"
          formControlName="clearanceTypeVal"
          [matAutocomplete]="clearanceTypeAutoComplete"
          (input)="
            formAutocompleteValueChanges(
              'clearanceTypeVal',
              'clearanceType',
              clearanceTypeData
            )"
            (focusout)="onClearanceFocusOut();
            clearErrors($event, 'clearanceTypeVal')
          " />
        <mat-autocomplete
          #clearanceTypeAutoComplete="matAutocomplete"
          (click)="onClearanceOptionSelected()"
          (keydown)="$event.stopPropagation()"
          (optionSelected)="
            formAutocompleteValueChanges(
            'clearanceTypeVal',
            'clearanceType',
            clearanceTypeData
          )">
          <mat-option
            [value]="item.name"
            *ngFor="let item of filteredClearanceTypeOptions | async"
            >{{ item.name }}</mat-option
          >
        </mat-autocomplete>
        <app-svg-icon
          *ngIf="checkControlHasError('clearanceType')"
          matSuffix
          [iconName]="actionIconsList.infoCircle"
          [isStaticSvg]="true"
          [isFrom]="svgIconFromList.page"
          class="cursor"
          (click)="fetchWidth($event, 'clearanceType')"
          (keydown)="$event.stopPropagation()"></app-svg-icon>
        <app-custom-error-tooltip
          *ngIf="
            checkControlHasError('clearanceType') &&
            setBagCriteriaErrorTooltip['clearanceType']
          "
          [tooltipWidth]="setBagCriteriaWidth['clearanceType']"
          [tooltipText]="isAutocompleteError('clearanceType')"
          (tooltipCloseClicked)="
            commonService.closeErrorTooltip(
              'clearanceType',
              setBagCriteriaErrorTooltip
            )
          ">
        </app-custom-error-tooltip>
      </mat-form-field>

      <mat-form-field appearance="outline" class="mat-md">
        <mat-label>Service Type Group</mat-label>
        <input
          matInput
          type="text"
          formControlName="serviceTypeGroupsVal"
          [matAutocomplete]="serviceTypeAutoComplete"
          (focusout)="
            dispatchCriteriaFormValueChanges();
            formAutocompleteValueChanges(
              'serviceTypeGroupsVal',
              'serviceTypeGroups',
              serviceTypeData
            );
            clearErrors($event, 'serviceTypeGroupsVal')
          " />
        <mat-autocomplete
          #serviceTypeAutoComplete="matAutocomplete"
          (optionSelected)="dispatchCriteriaFormValueChanges()">
          <mat-option
            [value]="item.name"
            *ngFor="let item of filteredServiceTypeOptions | async">
            {{ item.name }}
          </mat-option>
        </mat-autocomplete>
        <app-svg-icon
          *ngIf="checkControlHasError('serviceTypeGroupsVal')"
          matSuffix
          [iconName]="actionIconsList.infoCircle"
          [isStaticSvg]="true"
          [isFrom]="svgIconFromList.page"
          class="cursor"
          (click)="fetchWidth($event, 'serviceTypeGroupsVal')"
          (keydown)="$event.stopPropagation()"></app-svg-icon>
        <app-custom-error-tooltip
          *ngIf="
            checkControlHasError('serviceTypeGroupsVal') &&
            setBagCriteriaErrorTooltip['serviceTypeGroupsVal']
          "
          [tooltipWidth]="setBagCriteriaWidth['serviceTypeGroupsVal']"
          [tooltipText]="isAutocompleteError('serviceTypeGroupsVal')"
          (tooltipCloseClicked)="
            commonService.closeErrorTooltip(
              'serviceTypeGroupsVal',
              setBagCriteriaErrorTooltip
            )
          ">
        </app-custom-error-tooltip>
      </mat-form-field>

      <mat-form-field appearance="outline" class="mat-md">
        <mat-label>Object Group</mat-label>
        <input
          matInput
          type="text"
          formControlName="objectGroups"
          [matAutocomplete]="customerGroupIdAutoComplete"
          (focusout)="
            dispatchCriteriaFormValueChanges();
            commonService.getAutocompleteDropdownIdParam(
              customerGroupNameData,
              criteriaForm,
              'objectGroups',
              'objectGroupsVal'
            );
            clearErrors($event, 'objectGroups')
          " />
        <mat-autocomplete
          #customerGroupIdAutoComplete="matAutocomplete"
          (optionSelected)="dispatchCriteriaFormValueChanges()">
          <mat-option
            [value]="item.id"
            *ngFor="let item of filteredCustomerGroupNameOptions | async">
            {{ item.id }}
          </mat-option>
        </mat-autocomplete>
        <app-svg-icon
          *ngIf="checkControlHasError('objectGroups')"
          matSuffix
          [iconName]="actionIconsList.infoCircle"
          [isStaticSvg]="true"
          [isFrom]="svgIconFromList.page"
          class="cursor"
          (click)="fetchWidth($event, 'objectGroups')"
          (keydown)="$event.stopPropagation()"></app-svg-icon>
        <app-custom-error-tooltip
          *ngIf="
            checkControlHasError('objectGroups') &&
            setBagCriteriaErrorTooltip['objectGroups']
          "
          [tooltipWidth]="setBagCriteriaWidth['objectGroups']"
          [tooltipText]="isAutocompleteError('objectGroups')"
          (tooltipCloseClicked)="
            commonService.closeErrorTooltip(
              'objectGroups',
              setBagCriteriaErrorTooltip
            )
          ">
        </app-custom-error-tooltip>
      </mat-form-field>

      <mat-form-field appearance="outline" class="mat-md">
        <mat-label>Customer</mat-label>
        <input
          matInput
          type="text"
          formControlName="custId"
          [matAutocomplete]="customerIdAutoComplete"
          (focusout)="
            dispatchCriteriaFormValueChanges();
            formAutocompleteValueChanges('custId', 'custIdVal', customerIdData);
            clearErrors($event, 'custId')
          "
          placeholder="Enter Customer Code or Name" />
        <mat-autocomplete
          #customerIdAutoComplete="matAutocomplete"
          (optionSelected)="dispatchCriteriaFormValueChanges()">
          <mat-option
            [value]="item.name"
            *ngFor="let item of filteredCustomerIdOptions | async">
            {{ item.name }}
          </mat-option>
        </mat-autocomplete>
        <app-svg-icon
          *ngIf="checkControlHasError('custId')"
          matSuffix
          [iconName]="actionIconsList.infoCircle"
          [isStaticSvg]="true"
          [isFrom]="svgIconFromList.page"
          class="cursor"
          (click)="fetchWidth($event, 'custId')"
          (keydown)="$event.stopPropagation()"></app-svg-icon>
        <app-custom-error-tooltip
          *ngIf="
            checkControlHasError('custId') &&
            setBagCriteriaErrorTooltip['custId']
          "
          [tooltipWidth]="setBagCriteriaWidth['custId']"
          [tooltipText]="isAutocompleteError('custId')"
          (tooltipCloseClicked)="
            commonService.closeErrorTooltip(
              'custId',
              setBagCriteriaErrorTooltip
            )
          ">
        </app-custom-error-tooltip>
      </mat-form-field>
    </div>

    <div class="third-criteria-container">
      <div class="minmax">
        <mat-form-field appearance="outline" class="mat-md">
          <mat-label>Min Bag Weight(lb)</mat-label>
          <input
            #minHawbWeightInput
            matInput
            type="text"
            formControlName="minBagWeight"
            [appDigitWithTwoDecimalNumber]="7"
            (blur)="checkMinMaxHawbWeights('minBagWeight')"
            (input)="checkMinMaxHawbWeights('minBagWeight')"
            (focusout)="dispatchCriteriaFormValueChanges()" />
          <app-svg-icon
            *ngIf="criteriaControlError('minBagWeight') || minHawbWeightError"
            matSuffix
            [iconName]="actionIconsList.infoCircle"
            [isStaticSvg]="true"
            [isFrom]="svgIconFromList.page"
            class="cursor"
            (click)="fetchWidth($event, 'minBagWeight')"
            (keydown)="$event.stopPropagation()"></app-svg-icon>
          <app-custom-error-tooltip
            *ngIf="
              (criteriaControlError('minBagWeight') || minHawbWeightError) &&
              setBagCriteriaErrorTooltip['minBagWeight']
            "
            [tooltipWidth]="setBagCriteriaWidth['minBagWeight']"
            [tooltipText]="
              !minHawbWeightError
                ? ''
                : validationErrorMessages.minBagWeightError
            "
            (tooltipCloseClicked)="
              commonService.closeErrorTooltip(
                'minBagWeight',
                setBagCriteriaErrorTooltip
              )
            ">
          </app-custom-error-tooltip>
        </mat-form-field>
        <span>-</span>
        <mat-form-field appearance="outline" class="mat-md">
          <mat-label>Max Bag Weight(lb)</mat-label>
          <input
            #maxHawbWeightInput
            matInput
            type="text"
            formControlName="maxBagWeight"
            [appDigitWithTwoDecimalNumber]="7"
            (blur)="checkMinMaxHawbWeights('maxBagWeight')"
            (input)="checkMinMaxHawbWeights('maxBagWeight')"
            (focusout)="dispatchCriteriaFormValueChanges()" />
          <app-svg-icon
            *ngIf="criteriaControlError('maxBagWeight') || maxHawbWeightError"
            matSuffix
            [iconName]="actionIconsList.infoCircle"
            [isStaticSvg]="true"
            [isFrom]="svgIconFromList.page"
            class="cursor"
            (click)="fetchWidth($event, 'maxBagWeight')"
            (keydown)="$event.stopPropagation()"></app-svg-icon>
          <app-custom-error-tooltip
            *ngIf="
              (criteriaControlError('maxBagWeight') || maxHawbWeightError) &&
              setBagCriteriaErrorTooltip['maxBagWeight']
            "
            [tooltipWidth]="setBagCriteriaWidth['maxBagWeight']"
            [tooltipText]="
              !maxHawbWeightError
                ? ''
                : validationErrorMessages.maxBagWeightError
            "
            (tooltipCloseClicked)="
              commonService.closeErrorTooltip(
                'maxBagWeight',
                setBagCriteriaErrorTooltip
              )
            ">
          </app-custom-error-tooltip>
        </mat-form-field>
      </div>

      <div class="minmax">
        <mat-form-field appearance="outline" class="mat-md">
          <mat-label>Min Shipment Value($)</mat-label>
          <input
            #minHawbValueInput
            matInput
            type="text"
            formControlName="minHawbValue"
            [appDigitWithTwoDecimalNumber]="17"
            (blur)="checkMinMaxHawbValues('minHawbValue')"
            (input)="checkMinMaxHawbValues('minHawbValue')"
            (focusout)="dispatchCriteriaFormValueChanges()" />
          <app-svg-icon
            *ngIf="criteriaControlError('minHawbValue') || minHawbValueError"
            matSuffix
            [iconName]="actionIconsList.infoCircle"
            [isStaticSvg]="true"
            [isFrom]="svgIconFromList.page"
            class="cursor"
            (click)="fetchWidth($event, 'minHawbValue')"
            (keydown)="$event.stopPropagation()"></app-svg-icon>
          <app-custom-error-tooltip
            *ngIf="
              (criteriaControlError('minHawbValue') || minHawbValueError) &&
              setBagCriteriaErrorTooltip['minHawbValue']
            "
            [tooltipWidth]="setBagCriteriaWidth['minHawbValue']"
            [tooltipText]="
              !minHawbValueError
                ? ''
                : validationErrorMessages.minHawbValueError
            "
            (tooltipCloseClicked)="
              commonService.closeErrorTooltip(
                'minHawbValue',
                setBagCriteriaErrorTooltip
              )
            ">
          </app-custom-error-tooltip>
        </mat-form-field>
        <span>-</span>
        <mat-form-field appearance="outline" class="mat-md">
          <mat-label>Max Shipment Value($)</mat-label>
          <input
            #maxHawbValueInput
            matInput
            type="text"
            formControlName="maxHawbValue"
            [appDigitWithTwoDecimalNumber]="17"
            (blur)="checkMinMaxHawbValues('maxHawbValue')"
            (input)="checkMinMaxHawbValues('maxHawbValue')"
            (focusout)="dispatchCriteriaFormValueChanges()" />
          <app-svg-icon
            *ngIf="criteriaControlError('maxHawbValue') || maxHawbValueError"
            matSuffix
            [iconName]="actionIconsList.infoCircle"
            [isStaticSvg]="true"
            [isFrom]="svgIconFromList.page"
            class="cursor"
            (click)="fetchWidth($event, 'maxHawbValue')"
            (keydown)="$event.stopPropagation()"></app-svg-icon>
          <app-custom-error-tooltip
            *ngIf="
              (criteriaControlError('maxHawbValue') || maxHawbValueError) &&
              setBagCriteriaErrorTooltip['maxHawbValue']
            "
            [tooltipWidth]="setBagCriteriaWidth['maxHawbValue']"
            [tooltipText]="
              !maxHawbValueError
                ? ''
                : validationErrorMessages.maxHawbValueError
            "
            (tooltipCloseClicked)="
              commonService.closeErrorTooltip(
                'maxHawbValue',
                setBagCriteriaErrorTooltip
              )
            ">
          </app-custom-error-tooltip>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
