import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { IEventsStateModel } from 'src/app/shared/models/dynamic.model';
import { AddEvents, ResetEvents } from './events.action';

@Injectable()
@State<IEventsStateModel>({
  name: 'events',
  defaults: {
    events: [],
  },
})
export class EventsState {
  @Action(AddEvents)
  addEvents(ctx: StateContext<IEventsStateModel>, action: AddEvents) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      events: action.payload,
    });
  }

  @Action(ResetEvents)
  resetEvents(ctx: StateContext<IEventsStateModel>, action: ResetEvents) {
    ctx.setState({
      events: [],
    });
  }
}
