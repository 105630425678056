import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private openedPopcount = 0;

  constructor(
    private dialog: MatDialog,
    private commonService: CommonService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }

  public openPopup(code: any, title: any, component: any, width: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = width; // Dynamically set the width
    dialogConfig.data = {
      title: title,
      code: code,
    };
    const dialogRef = this.dialog.open(component, dialogConfig);
    dialogRef
      .afterOpened()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.commonService.setOverlayOpen(true);
        this.openedPopcount++;
      });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.openedPopcount--;
        if (this.openedPopcount === 0) {
          this.commonService.setOverlayOpen(false);
        }
      });
    return dialogRef;
  }
}
